import { stopPlay } from "../api/play";

const handleLeavePlay = async (
  accessToken,
  leavePlayPath,
  setLeavePlayPath
) => {
  try {
    await stopPlay(accessToken);
  } catch (err) {
    console.error(err);
  } finally {
    setLeavePlayPath("");
    window.location.href = leavePlayPath;
  }
};

export default handleLeavePlay;
