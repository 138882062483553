import { Box } from "@mui/material";
import getBorderlines from "./getBorderlines";

const DrawBorderlines = ({ gridCoords, regions }) => {
  if (!gridCoords?.x || !regions || regions.length < 1) return null;

  return regions.map((regionArr) => {
    const { top, left, right, bottom } = getBorderlines(
      gridCoords,
      regionArr[0],
      regionArr[1]
    );

    return (
      <>
        <Box
          sx={{
            position: "absolute",
            zIndex: 100,
            top: `${top}%`,
            left: `${left}%`,
            width: `${right - left}%`,
            height: `${bottom - top}%`,
            backgroundColor: "#ff110042",
          }}
        />
        {/* <Box
          sx={{
            position: "absolute",
            zIndex: 100,
            top: `${top}%`,
            left: `${right}%`,
            width: "2px",
            height: `${bottom - top}%`,
            backgroundColor: "blue",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            zIndex: 100,
            top: `${bottom}%`,
            left: `${left}%`,
            width: `${right - left}%`,
            height: "2px",
            backgroundColor: "blue",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            zIndex: 100,
            top: `${top}%`,
            left: `${left}%`,
            width: "2px",
            height: `${bottom - top}%`,
            backgroundColor: "blue",
          }}
        /> */}
      </>
    );
  });
};

export default DrawBorderlines;
