import { useLocation } from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Meta from "../../components/Meta";
import AuthSection from "./AuthSection";
import AuthLongContent from "./AuthLongContent";

const AuthLanding = () => {
  const location = useLocation();
  const pathName = location?.pathname;
  const authType = pathName?.substring(pathName.lastIndexOf("/") + 1);
  const isBusiness = authType === "business";
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Meta
        title="Neurorobotics Studio"
        description="Create robot brains using advanced AI with neuroscience principles"
      />
      <Box
        sx={{
          width: "100%",
          maxWidth: "100vw",
          height: "100%",
          pt: { xs: "20px", md: 0 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {!isSmOrSmaller && (
          <AuthLongContent
            sx={{
              height: {
                xs: "calc(100vh - 72px)",
                lg: "calc(100vh - 72px)",
              },
              overflowY: "scroll",
            }}
          />
        )}
        {/* Image */}
        {/* <Box
          sx={{
            width: "100%",
            height: "100%",
            display: { xs: "none", md: "flex" },
            alignItems: "flex-end",
            justifyContent: { md: "center", lg: "flex-start" },
            backgroundColor: "#131b21",
            backgroundImage: `url(${scribbleBrainWhite})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: { xs: "75%", xl: "55%" },
          }}
        >
          <Typography
            sx={{
              p: 2,
              mb: 18,
              ml: { md: 0, lg: 18, xl: 26 },
              fontSize: "1.9rem",
              color: "white",
              fontWeight: "300",
            }}
          >
            Let's build{" "}
            <Typography
              component="span"
              sx={{ fontWeight: "600", fontSize: "1.9rem" }}
            >
              Brains for Robots™
            </Typography>
          </Typography>
        </Box> */}

        {/* Auth section */}
        <Box
          sx={{
            width: { xs: "100%", md: "750px", lg: "650px", xl: "600px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isSmOrSmaller && <AuthLongContent />}
          {(!isSmOrSmaller ||
            location.pathname === "/" ||
            location.pathname === "/auth/signup") && (
            <Box sx={{ width: "100%", px: "30px" }}>
              <AuthSection
                bgColor="default"
                size="auto"
                type={authType}
                providers={["github"]}
                afterAuthPath={isBusiness ? "/pricing" : "/lab"}
                // afterAuthPath={router.query.next || "/lab"}
                isBusiness={isBusiness}
                hideFooter={isSmOrSmaller}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AuthLanding;
