const cameraCapabilities = {
  capabilities: {
    input: {
      camera: {
        0: {
          custom_name: "media_capture",
          disabled: false,
          eccentricity_control: {
            "X offset percentage": 15,
            "Y offset percentage": 15,
          },
          feagi_index: 0,
          index: "00",
          mirror: false,
          modulation_control: {
            "X offset percentage": 85,
            "Y offset percentage": 85,
          },
          threshold_default: 50,
        },
      },
    },
  },
};

export default cameraCapabilities;
