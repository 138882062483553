import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";
import { apiFilterNonNull } from "./apiFilterNonNull";
import { localLogger, logger } from "../util/logger";

// Start OAuth process for signing up/in with provider
export const startOAuthProcess = async (
  provider,
  params,
  adminToken = process.env.REACT_APP_PROJECT_ID
) => {
  try {
    apiValidateArguments({ provider, adminToken });

    if (provider !== "github" && provider !== "google") {
      throw new Error(
        "Invalid provider--expecting 'google' or 'github':",
        provider
      );
    }

    const baseUrl = `${process.env.REACT_APP_COMPOSER_URL}/v1/public/global/get-auth/oauth`;
    const providerString = `provider_name=${provider}.com`;
    if (typeof params === "string" && !params.startsWith("?")) {
      params = `?${params}`;
    }
    const url = `${baseUrl}${params ? `${params}&` : "?"}${providerString}`;

    const response = await apiRequestExternal(
      url,
      "GET",
      null,
      { Authorization: adminToken },
      true,
      false
    );
    localLogger("response in startOAuthProcess function:", response);
    const oAuthUrl = response.data.authorization_url;
    window.location.href = oAuthUrl;
  } catch (err) {
    console.error("Error initiating OAuth:", err);
    throw err;
  }
};

// Submit email validation token
export const submitEmailToken = async (
  email,
  emailToken,
  adminToken = process.env.REACT_APP_PROJECT_ID
) => {
  try {
    // Validate args
    apiValidateArguments({ email, emailToken, adminToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/global/get-auth/validate_account_validation_token`,
      "POST",
      { email: email, token: emailToken },
      { Authorization: adminToken },
      false,
      false
    );

    localLogger("Response in submitEmailToken function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in submitEmailToken function:", err);
    throw err;
  }
};

// Resend email validation token email
export const resendEmailToken = async (
  email,
  adminToken = process.env.REACT_APP_PROJECT_ID
) => {
  try {
    // Validate args
    apiValidateArguments({ email, adminToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/global/get-auth/email_account_validation_token`,
      "POST",
      { email },
      { Authorization: adminToken },
      false,
      false,
      2
    );

    localLogger("Response in resendEmailToken function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in resendEmailToken function:", err);
    throw err;
  }
};

// get regions
export async function getRegions(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/supported_regions`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getRegions function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getRegions function:", error);
    throw error;
  }
}

// create account / register
export async function createUser(
  email,
  password,
  params,
  adminToken = process.env.REACT_APP_PROJECT_ID
) {
  try {
    apiValidateArguments({ email, password, adminToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${
        process.env.REACT_APP_COMPOSER_URL
      }/v1/public/global/get-auth/register${params ? `${params}` : ""}`,
      "POST",
      { email, password },
      { Authorization: adminToken },
      false,
      false
    );

    localLogger("Response in createUser function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in createUser function:", error);
    throw error;
  }
}

// check access key
export async function checkAccessKey(
  accessKey,
  adminToken = process.env.REACT_APP_PROJECT_ID
) {
  try {
    // Validate args
    apiValidateArguments({ accessKey, adminToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/early_access_check`,
      "POST",
      { early_access_key: accessKey },
      { Authorization: adminToken },
      false,
      false
    );

    localLogger("Response in checkAccessKey function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in checkAccessKey function:", error);
    throw error;
  }
}

// get public user profile info
export async function getUserProfile(accessToken, displayName) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/profile/public?display_name=${displayName}`,
      "GET",
      null,
      { Authorization: accessToken },
      false,
      false
    );

    localLogger("Response in getUserProfile function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getUserProfile function:", error);
    throw error;
  }
}

// Get all avatar image urls for user profiles
export async function getAvatarLinks(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/profile/avatar_options`,
      "GET",
      null,
      { Authorization: accessToken },
      false,
      false
    );

    localLogger("Response in getAvatarLinks function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getAvatarLinks function:", error);
    throw error;
  }
}

// get user by Firebase Auth uid (via token)
export async function getUserByToken(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/profile/by_token`,
      "GET",
      null,
      { Authorization: accessToken },
      false,
      false
    );

    localLogger("Response in getUserByToken function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getUserByToken function:", error);
    throw error;
  }
}

// update user
export async function updateUser(
  accessToken,
  email,
  username,
  description,
  avatar,
  region
) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Filter to only pass provided args in request body
    const body = apiFilterNonNull({
      email,
      display_name: username,
      description,
      avatar,
      region,
    });

    if (Object.keys(body).length === 0) {
      throw new Error("No new data provided to update user.");
    }

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/profile`,
      "PUT",
      body,
      { Authorization: accessToken },
      false,
      false
    );

    localLogger("Response in updateUser function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in updateUser function:", error);
    throw error;
  }
}

// get all of a user's experiments
export async function getUserExperiments(accessToken, skip = 0, limit = 10) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/experiments?skip=${skip}&limit=${limit}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getUserExperiments function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getUserExperiments function:", error);
    throw error;
  }
}

// tell backend that user is active
export async function updateUserHeartbeat(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/user_session/heartbeat`,
      "PUT",
      null,
      { Authorization: accessToken }
    );

    logger("Response in updateUserHeartbeat function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in updateUserHeartbeat function:", error);
    throw error;
  }
}

// fetch latest user roles state
export async function getUserRoles(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/roles`,
      "GET",
      null,
      { Authorization: accessToken },
      false,
      false
    );

    localLogger("Response in getUserRoles function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getUserRoles function:", error);
    throw error;
  }
}

// ask backend if user is active via other tabs/devices
export async function checkUserHeartbeat(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/user_session/last_active`,
      "POST",
      null,
      { Authorization: accessToken }
    );

    logger("Response in checkUserHeartbeat function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in checkUserHeartbeat function:", error);
    throw error;
  }
}

// Send validation token for new email of existing user
export const sendUserEmailToken = async (accessToken, email) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken, email });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/email_account_validation_token`,
      "POST",
      { email },
      { Authorization: accessToken }
    );

    localLogger("Response in sendUserEmailToken function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in sendUserEmailToken function:", err);
    throw err;
  }
};

// Change email
export const changeUserEmail = async (accessToken, email, emailToken) => {
  try {
    // Validate args
    apiValidateArguments({ email, emailToken, accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/update_user_email`,
      "POST",
      { email, token: emailToken },
      { Authorization: accessToken }
    );

    localLogger("Response in changeUserEmail function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in changeUserEmail function:", err);
    throw err;
  }
};

// Update tutorial progress
export const updateTutorialProgress = async (
  accessToken,
  completedChapterId
) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken, completedChapterId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/update_tutorial_progress`,
      "PUT",
      { completed_chapter_id: completedChapterId },
      { Authorization: accessToken }
    );

    localLogger("Response in updateTutorialProgress function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in updateTutorialProgress function:", err);
    throw err;
  }
};

export const unlockAdvanced = async (accessToken) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/users/show_advanced_tutorials`,
      "POST",
      { Authorization: accessToken }
    );

    localLogger("Response in unlockAdvanced function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in unlockAdvanced function:", err);
    throw err;
  }
};
