import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Box, Container, Typography } from "@mui/material";
import disappointedRobot from "../img/robot-bewildered-transparent.png";

function ErrorTopLevel(props) {
  return (
    <Section
    //   bgColor={props.bgColor}
    //   size={props.size}
    //   bgImage={props.bgImage}
    //   bgImageOpacity={props.bgImageOpacity}
    >
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SectionHeader
          title="Error Occurred"
          subtitle=""
          size={4}
          textAlign="center"
          sx={{
            backgroundColor: "rgb(16, 18, 20) !important",
            color: "rgb(255, 255, 255) !important",
          }}
        />
        <Box
          marginTop="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ color: "rgb(255, 255, 255)" }}
        >
          <Typography>Sorry, an unknown error occurred!</Typography>
          <Typography>Please reload and/or go back.</Typography>
          <img
            src={disappointedRobot}
            alt="bewildered robot"
            style={{ maxWidth: "400px" }}
          />
        </Box>
      </Container>
    </Section>
  );
}

export default ErrorTopLevel;
