import { Box, Typography } from "@mui/material";
import globe from "../img/navigation.png";
import SocialLinks from "../components/SocialLinks";

const RegionUnsupportedPage = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        mx: { xs: 2, md: 0 },
      }}
    >
      <Typography variant="h4">Region Unsupported</Typography>
      <Box sx={{ width: { xs: "300px", md: "200px" } }}>
        <img
          src={globe}
          alt="globe with location pointers"
          style={{ maxWidth: "100%" }}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Typography>
          The region you are logging in from is not currently supported by
          Neurorobotics Studio.
        </Typography>
        <Typography>Supported Regions: United States</Typography>
        <Typography>
          We plan to offer support for other regions in the future.{" "}
          <strong>Follow us for updates!</strong>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <SocialLinks />
      </Box>
    </Box>
  );
};

export default RegionUnsupportedPage;
