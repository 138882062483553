import Meta from "../components/Meta";
import LabSection from "../components/lab/LabSection";
import { requireAuth } from "../util/auth";

function LabPage() {
  return (
    <>
      <Meta title="My Neurorobotics Lab" />
      <LabSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="My Neurorobotics Lab"
        subtitle=""
        // setGodotUrl={props.setGodotUrl}
        // setWebcamUrl={props.setWebcamUrl}
      />
    </>
  );
}

export default requireAuth(LabPage);
