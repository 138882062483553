import { Box, Typography } from "@mui/material";

function SectionHeader(props) {
  const {
    subtitle,
    title,
    size,
    className,
    fontWeight,
    bgColor,
    styles,
    ...otherProps
  } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      sx={{
        width: "100%",
        padding: "7px 0",
        borderRadius: "5px",
        backgroundColor: bgColor || "background.paper",
        ...styles,
        // "&:not(:last-child)": {
        //   marginBottom: "2rem",
        // },
      }}
      {...otherProps}
    >
      {title && (
        <Typography
          variant={`h${size}`}
          gutterBottom={props.subtitle ? true : false}
          sx={{ fontWeight: props.titleFontWeight }}
        >
          {title}
        </Typography>
      )}

      {subtitle && (
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: props.subtitleFontWeight,
            fontSize: props.subtitleFontSize,
            maxWidth: 700,
            display: "inline-block",
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}

export default SectionHeader;
