import { Alert, Snackbar } from "@mui/material";

// MUI severity options: success, info, warning, error

const SnackbarMessage = ({
  message,
  setMessage,
  severity,
  top = "7px",
  autoHideTime = null,
}) => {
  return (
    <Snackbar
      open={message}
      autoHideDuration={autoHideTime}
      onClose={() => setMessage("")}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{
        "&.MuiSnackbar-root": { top: top },
      }}
    >
      <Alert onClose={() => setMessage("")} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
