import Tooltip from "@mui/material/Tooltip";
import {
  Apple,
  Bluetooth,
  CenterFocusStrong,
  Microsoft,
  PrecisionManufacturing,
} from "@mui/icons-material";

const requirementIcons = {
  camera: { icon: <CenterFocusStrong />, tooltip: "Camera" },
  physical: { icon: <PrecisionManufacturing />, tooltip: "Physical" },
  bluetooth: { icon: <Bluetooth />, tooltip: "Bluetooth" },
  windows: { icon: <Microsoft />, tooltip: "Windows" },
  mac: { icon: <Apple />, tooltip: "Mac" },
};

const PlayIcons = ({ items }) => {
  if (!items) return null;

  return (
    <>
      {items.map((req) => {
        if (requirementIcons[req]) {
          return (
            <Tooltip key={req} title={requirementIcons[req].tooltip}>
              {requirementIcons[req].icon}
            </Tooltip>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default PlayIcons;
