import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { useAuth } from "../util/auth";
import withTimeout from "../util/timeout";
import getBrowserInfo from "../util/getBrowserInfo";
import Meta from "./Meta";
import PageLoader from "./PageLoader";
import BrainVisualizerIntro from "./BrainVisualizerIntro";
import Webcam from "./embodiments-code/Webcam";
import {
  getExperimentIdBySessionId,
  getFeagiSessionInfo,
  // getSessionFeagiStatus,
} from "../api/feagiSessionManagement";

const BrainVisualizer = ({ webcamOpen, setWebcamOpen, navbarHeight }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  // Get session & cluster IDs from URL
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const sessionId = queryParameters.get("id");
  const cluster = queryParameters.get("cluster");
  // Misc
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [iframeHeight, setIframeHeight] = useState("80vh");
  const [isSessionValid, setIsSessionValid] = useState(null);
  const [isAdvancedParam, setIsAdvancedParam] = useState("");
  // Intro dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  // Error handling
  const [error, setError] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => setErrorOpen(false);
  // Handle brain health check fail
  const [feagiDownOpen, setFeagiDownOpen] = useState(false);
  const BRAIN_CHECK_MINUTES = 5;

  useEffect(() => {
    if (navbarHeight) {
      setIframeHeight(`calc(100vh - ${navbarHeight}px)`);
    }
  }, [navbarHeight]);

  // Detect if mobile
  useEffect(() => {
    async function fetchBrowserInfo() {
      const info = await getBrowserInfo();
      // setBrowserInfo(info);
      if (info?.isMobile) {
        setIsMobile(true);
        setIsLoading(false);
      }
    }

    fetchBrowserInfo();
  }, []);

  // Check session validity and advanced mode setting
  useEffect(() => {
    const fetchSessionInfo = async () => {
      try {
        // Get session info
        const accessToken = auth.user?.accessToken;
        const ms = 180000;
        const res = await withTimeout(
          getFeagiSessionInfo(accessToken, sessionId),
          ms,
          `Timeout after ${ms / 60000} minutes`
        );
        // Set iframe param for advanced mode
        const isAdvanced = res.data?.bv_preferences?.is_advanced_mode;
        if (typeof isAdvanced === "boolean") {
          setIsAdvancedParam(`&is_advanced_mode=${isAdvanced}`);
        }
        // Set if session is valid (triggers iframe rendering)
        setIsSessionValid(res.status !== 404);
      } catch (error) {
        // Handle errors
        setIsSessionValid(false);
        console.error("Error retrieving experiment session info:", error);
        setFeagiDownOpen(true);
      } finally {
        // Stop loading
        setIsLoading(false);
      }
    };

    fetchSessionInfo();
  }, [sessionId, auth.user?.accessToken]);

  useEffect(() => {
    if (!localStorage.getItem("dontShowBrainVisualizerIntro")) {
      setDialogOpen(true);
    }
  }, []);

  // Check FEAGI health periodically
  // useEffect(() => {
  //   const checkFeagiHealth = async () => {
  //     try {
  //       const response = await getSessionFeagiStatus(
  //         auth.user?.accessToken,
  //         sessionId
  //       );

  //       if (response.data.brain_readiness) {
  //         feagiDownOpen && setFeagiDownOpen(false);
  //       }

  //       if (!response.data.brain_readiness) {
  //         !feagiDownOpen && setFeagiDownOpen(true);
  //       }
  //     } catch (error) {
  //       console.error("Failed to check brain health:", error);
  //       setFeagiDownOpen(true);
  //     }
  //   };
  //   // Check the brain's health every x amount of time
  //   const intervalId = setInterval(checkFeagiHealth, 30000);
  //   // Clear the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, [auth.user, sessionId, feagiDownOpen]);

  // If user chooses to return to lab when brain down
  const returnToLab = async () => {
    try {
      navigate(`/lab`);
    } catch (err) {
      console.error("Error redirecting to lab:", err);
      // set/show error
    }
  };

  // If user chooses to restart when brain down
  const restartExperiment = async () => {
    try {
      if (!auth.user?.accessToken || !sessionId) {
        throw new Error("Missing required arguments to get experiment ID.");
      }
      const res = await getExperimentIdBySessionId(
        sessionId,
        auth.user?.accessToken
      );
      const experimentId = res.data.experiment_id;
      navigate(`/brain-loading?id=${experimentId}`);
    } catch (err) {
      console.error(
        "Error redirecting to brain loading with experiment ID:",
        err
      );
      // set/show error
    }
  };

  // Return to lab after BRAIN_CHECK_MINUTES if the dialog is still open
  useEffect(() => {
    let timeout;
    if (feagiDownOpen) {
      timeout = setTimeout(() => {
        returnToLab();
      }, BRAIN_CHECK_MINUTES * 60000);
    }

    return () => clearTimeout(timeout); // Cleanup on unmount or if feagiDownOpen changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feagiDownOpen]);

  // Loading spinner
  if (isLoading) {
    return <PageLoader />;
  }

  // Mobile message
  if (isMobile) {
    return (
      <Box
        sx={{
          px: 2,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography>
          The brain visualizer is too mighty for mobile devices. Please use a
          desktop or laptop computer.
        </Typography>
        <Button
          variant="contained"
          onClick={() => (window.location.href = "/lab")}
        >
          To the Lab!
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Meta
        http-equiv="Cache-Control"
        content="no-cache, no-store, must-revalidate"
      />
      {/* Brain Visualizer */}
      {isSessionValid && (
        <iframe
          title="Brain Visualizer"
          src={`${process.env.PUBLIC_URL}/static/godot/index.html?ip_address=${sessionId}-feagi.${cluster}.neurorobotics.studio&port_disabled=true${isAdvancedParam}&websocket_url=wss://${sessionId}-feagi.${cluster}.neurorobotics.studio/p9050&http_type=HTTPS://`}
          name={`${Date.now().toString()}`}
          // height={`calc(100% - ${navbarHeight}px)` || "80%"}
          // height="80%"
          style={{ height: iframeHeight, width: "100%", border: "none" }}
          sandbox="allow-scripts allow-popups allow-same-origin allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
          allow="cross-origin-isolation"
        />
      )}

      {/* Intro modal */}
      <BrainVisualizerIntro
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />

      {/* Error modal */}
      <Dialog
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="error-title"
        maxWidth="lg"
      >
        <DialogTitle id="error-title">
          Sorry, an error occurred
          <IconButton
            style={{ position: "absolute", right: 7, top: 7, padding: 3 }}
            onClick={handleErrorClose}
            size="small"
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: "40vw" }}>
          {error} Please retry or reload and try again.
        </DialogContent>
      </Dialog>

      {/* FEAGI down modal */}
      <Dialog
        open={feagiDownOpen}
        onClose={() => {}}
        BackdropProps={{ style: { pointerEvents: "none" } }}
        aria-labelledby="brain-down-title"
        aria-describedby="brain-down-description"
      >
        <DialogTitle id="brain-down-title">Brain Health Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="brain-down-description">
            The brain is currently in poor health. We'll check on it for{" "}
            {BRAIN_CHECK_MINUTES} minutes, then return to the lab.
          </DialogContentText>
          <Box textAlign="center" my={4}>
            <CircularProgress />
          </Box>
          <DialogContentText id="brain-down-description">
            You can continue to await its recovery, or:
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", pb: 2, px: 3 }}>
          <Button variant="contained" onClick={restartExperiment}>
            Restart Experiment
          </Button>
          <Button variant="contained" onClick={returnToLab}>
            Return to Lab
          </Button>
        </DialogActions>
      </Dialog>

      {/* Webcam */}
      {isSessionValid && webcamOpen && (
        <Webcam
          webcamOpen={webcamOpen}
          setWebcamOpen={setWebcamOpen}
          setError={setError}
          handleErrorOpen={handleErrorOpen}
        />
      )}
    </>
  );
};

export default BrainVisualizer;
