import { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Box,
  Collapse,
  IconButton,
  List,
  ListItemText,
  ListItemButton,
  Menu,
  Typography,
} from "@mui/material";
import {
  Close,
  ExpandLess,
  ExpandMore,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { requireAuth, useAuth } from "../util/auth";
import DisplayMarkdown from "../util/DisplayMarkdown";
import PageLoader from "./PageLoader";
import SnackbarMessage from "./SnackbarMessage";

function Book({ fetchData, header }) {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const hamburgerOpen = Boolean(anchorEl);
  const videoRefs = useRef([]);
  const slideRefs = useRef([]);
  const [slideRefsSet, setSlideRefsSet] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  // Set data on load
  useEffect(() => {
    async function callFetchData() {
      try {
        const accessToken = auth.user.accessToken;
        const res = await fetchData(accessToken);
        const resData = res.data.outline;
        if (resData?.length > 0) {
          setData(resData);
        } else {
          throw new Error("Response data from server is empty.");
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(
          "Sorry, an error occurred fetching the data. Please reload and retry if issues persist."
        );
        setLoading(false);
      }
    }

    auth?.user && callFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user, setError]);

  // Open/close table of contents on mobile
  const handleHamburgerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHamburgerClose = () => {
    setAnchorEl(null);
  };

  // User click to previous / next section
  const goPrev = () => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex((prev) => prev - 1);
    }
  };

  const goNext = () => {
    if (currentSectionIndex < data.length - 1) {
      setCurrentSectionIndex((prev) => prev + 1);
    }
  };

  // Handle slide images or videos flexibly
  const determineSlideFormat = (url) => {
    if (typeof url !== "string") return null;
    // List of common image and video file extensions
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv"];

    // Extract the extension from the URL
    const extension = url.split(".").pop().toLowerCase();

    // Determine the format based on the extension
    if (imageExtensions.includes(extension)) {
      return "image";
    } else if (videoExtensions.includes(extension)) {
      return "video";
    } else {
      return null;
    }
  };

  // Handle user click on slide or section header
  const handleSlideClick = (sectionIdx, slideIdx) => {
    if (sectionIdx !== currentSectionIndex) {
      // If switching sections, update the state, uncollapse new section, and reset slide index
      setCurrentSectionIndex(sectionIdx);
      setShouldCollapse(false);
    } else {
      if (slideIdx === null || slideIdx === undefined) {
        // If this is a click on a section header, just collapse/uncollapse the section
        setShouldCollapse((prev) => !prev);
      } else {
        // If within same section, just scroll to the slide
        slideRefs.current[sectionIdx]?.[slideIdx]?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        if (hamburgerOpen) {
          setSlideIndex(slideIdx);
          handleHamburgerClose();
        }
      }
    }
  };

  // When section index changes, set slide to 0 and scroll to top
  useEffect(() => {
    setSlideIndex(0);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setSlideRefsSet((prev) => !prev);
  }, [currentSectionIndex]);

  // Sync current slide to scroll position
  useEffect(() => {
    if (
      slideRefs.current[currentSectionIndex] &&
      slideRefs.current[currentSectionIndex].length > 0
    ) {
      // Snapshot to keep track of current section's slides for cleanup
      const slidesInSection = slideRefs.current[currentSectionIndex] || [];
      // Update slide index on scroll
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const idx = slidesInSection.findIndex(
                (slide) => slide === entry.target
              );
              if (idx !== -1) {
                setSlideIndex(idx);
              }
            }
          });
        },
        { threshold: 0.5 }
      );
      // Set up the observer for the current section's slides
      slidesInSection.forEach((slide) => {
        if (slide instanceof Element) {
          observer.observe(slide);
        }
      });
      // Cleanup function for the current section's slides
      return () => {
        slidesInSection.forEach((slide) => {
          if (slide instanceof Element) {
            observer.unobserve(slide);
          }
        });
        observer.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideRefsSet]);

  // Mute/unmute video on user click
  const handleVolumeChange = (event) => {
    const video = event.target;
    setIsMuted(video.muted);
  };

  // Track scroll/video position to play only on scroll into view
  useEffect(() => {
    // Snapshot the current state of videoRefs
    const currentVideos = [...videoRefs.current];

    // Function to pause all videos except the one that triggered the play event
    const pauseOtherVideos = (currentVideo) => {
      currentVideos.forEach((video) => {
        if (video !== currentVideo) {
          video.pause();
        }
      });
    };

    // Set up what observer should do
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Check if the video element has the 'controls' attribute
          const hasControls = entry.target.hasAttribute("controls");

          if (entry.isIntersecting) {
            // Only play on view if no controls
            if (!hasControls) {
              entry.target.play();
            }
          } else {
            entry.target.pause(); // Pause video on exiting the viewport regardless of controls
          }
        });
      },
      {
        threshold: 0.5, // Customize based on when you want the video to play
      }
    );
    // Observe videos based on the snapshot
    currentVideos.forEach((video) => {
      if (video) {
        observer.observe(video);

        // Attach an event listener to pause other videos when this one plays
        video.addEventListener("play", () => pauseOtherVideos(video));
      }
    });

    // Cleanup on component unmount
    return () => {
      currentVideos.forEach((video) => {
        if (video) {
          observer.unobserve(video);
          // Ideally remove play listener here, but requires storing an array of listener functions
          // video.removeEventListener("play", /* specific reference to the pause function */);
        }
      });
      observer.disconnect();
    };
  }, [data]);

  const TableOfContents = () => {
    return (
      <List
        component="nav"
        aria-label="table of contents"
        sx={{
          overflow: "auto",
          // height: {
          //   xs: `calc(100% - ${APP_BAR_HEIGHT_MOBILE})`,
          //   md: "100%",
          // },
        }}
      >
        {data.map((sectionObject, sectionObjIndex) => {
          // Section title
          return (
            <Fragment key={sectionObject.title}>
              <ListItemButton
                onClick={() => handleSlideClick(sectionObjIndex)}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent:
                    currentSectionIndex === sectionObjIndex
                      ? "space-between"
                      : "flex-start",
                  // backgroundColor:
                  //   currentSectionIndex === sectionObjIndex
                  //     ? "rgba(144, 202, 249, 0.16)"
                  //     : "inherit",
                  color:
                    currentSectionIndex === sectionObjIndex
                      ? "primary.main"
                      : "",
                }}
              >
                <ListItemText
                  primary={sectionObject.title}
                  primaryTypographyProps={{ fontWeight: "600" }}
                />
                {currentSectionIndex === sectionObjIndex && !shouldCollapse ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItemButton>
              {/* Slide title */}
              {sectionObject.slides.map((slide, sIndex) => (
                <Collapse
                  in={
                    currentSectionIndex === sectionObjIndex && !shouldCollapse
                  }
                  timeout="auto"
                  unmountOnExit
                  key={slide.title}
                >
                  <ListItemButton
                    selected={
                      currentSectionIndex === sectionObjIndex &&
                      slideIndex === sIndex
                    }
                    onClick={() => handleSlideClick(sectionObjIndex, sIndex)}
                    sx={{ ml: 2 }}
                  >
                    <ListItemText
                      primary={slide.title || "Untitled"}
                      primaryTypographyProps={{
                        fontSize: ".9rem",
                        fontStyle: !slide.title ? "italic" : "normal",
                      }}
                    />
                  </ListItemButton>
                </Collapse>
              ))}
            </Fragment>
          );
        })}
      </List>
    );
  };

  return loading ? (
    <PageLoader />
  ) : (
    <>
      {/* Error snackbar */}
      {error && (
        <SnackbarMessage
          message={error}
          setMessage={setError}
          severity="error"
        />
      )}

      {/* Dialog Header */}
      <Box
        sx={{
          position: { xs: "sticky", md: "relative" },
          top: 0,
          zIndex: 100,
          display: "flex",
          justifyContent: { xs: "flex-end", md: "center" },
          alignItems: "center",
          gap: "10px",
        }}
      >
        {/* Table of Contents (Mobile) */}
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
          }}
        >
          <Menu
            id="table-of-contents"
            aria-labelledby="table-of-contents-button"
            anchorEl={anchorEl}
            open={hamburgerOpen}
            onClose={handleHamburgerClose}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPaper-root": {
                border: "5px solid #1d1d1d",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 2, fontWeight: "600" }}>
                Table of Contents
              </Typography>
              <IconButton onClick={handleHamburgerClose}>
                <Close />
              </IconButton>
            </Box>
            <TableOfContents />
          </Menu>
          <IconButton
            id="table-of-contents-button"
            aria-controls="table-of-contents"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handleHamburgerClick}
            sx={{
              mr: { xs: 2, md: 6, xl: 10 },
              mt: { xs: 1, md: 0 },
              backgroundColor: "background.default",
              borderRadius: "50px",
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Header on both */}
        {/* <Typography
          sx={{
            variant: "h2",
            fontSize: "clamp(1rem, 2vw, 1.5rem)",
            fontWeight: "600",
            textTransform: "capitalize",
          }}
        >
          {header}
        </Typography> */}
      </Box>

      {/* Dialog Content */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          bgcolor: "background.default",
        }}
      >
        {/* Table of Contents (Desktop) */}
        <Box
          bgcolor="background.default"
          sx={{
            display: { xs: "none", md: "block" },
            position: "sticky",
            top: 0,
            height: "100vh",
            width: "25%",
          }}
        >
          <TableOfContents />
        </Box>

        {/* Content */}
        <Box
          id="slidesParent"
          bgcolor="background.default"
          // justifyContent="flex-end" adding this will make the content fly through top and lose scrollbar
          sx={{
            width: { xs: "100%", md: "75%" },
            mx: { xs: 2, md: 6, xl: 10 },
            overflow: "auto",
          }}
        >
          {data[currentSectionIndex]?.slides?.map((slide, sIndex) => (
            <Box
              key={sIndex}
              ref={(el) => {
                if (!slideRefs.current[currentSectionIndex]) {
                  slideRefs.current[currentSectionIndex] = [];
                }
                slideRefs.current[currentSectionIndex][sIndex] = el;
                if (data[currentSectionIndex].slides.length - 1 === sIndex) {
                  setSlideRefsSet((prev) => !prev);
                }
              }}
              sx={{
                // minHeight: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: { xs: "center", md: "space-between" },
                alignItems: "center",
                py: "20px",
                gap: { xs: 2, md: "" },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    marginBottom: "10px",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  {slide.title}
                </Typography>
              </Box>
              {determineSlideFormat(slide.url) === "image" ? (
                <img
                  src={slide.url}
                  alt={slide.title}
                  crossorigin="anonymous"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                    borderRadius: "5px",
                  }}
                />
              ) : determineSlideFormat(slide.url) === "video" ? (
                <video
                  src={`${slide.url}#t=0.001`}
                  crossorigin="anonymous"
                  ref={(el) => {
                    if (el) {
                      if (!videoRefs.current.includes(el)) {
                        videoRefs.current.push(el);
                      }
                    }
                  }}
                  preload="metadata"
                  autoPlay={slide.controls ? false : true}
                  muted={isMuted}
                  loop={slide.loop ? true : false}
                  controls={slide.controls ? true : false}
                  onVolumeChange={handleVolumeChange}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <Typography
                  fontStyle="italic"
                  bgcolor="rgba(144, 202, 249, 0.16)"
                  sx={{ my: "20px", padding: "100px" }}
                >
                  Slide unavailable.
                </Typography>
              )}
              {slide.description && (
                <Typography
                  sx={{
                    width: "100%",
                    marginTop: "10px",
                    fontSize: { xs: "1rem", md: "1.2rem" },
                    fontWeight: "500",
                  }}
                >
                  <DisplayMarkdown markdownFromDb={slide.description} />
                </Typography>
              )}
            </Box>
          ))}

          {/* Previous / Next buttons */}
          <Box
            sx={{
              width: "100%",
              mb: { xs: 0, md: 2 },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={goPrev}
              disabled={currentSectionIndex === 0}
              variant="outlined"
              sx={{ mr: "20px" }}
            >
              Prev
            </Button>
            <Button
              onClick={goNext}
              disabled={currentSectionIndex === data.length - 1}
              variant="outlined"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default requireAuth(Book);
