import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Info as InfoIcon,
  // VolumeOff as VolumeOffIcon,
  // VolumeUp as VolumeUpIcon,
} from "@mui/icons-material";
import { useAuth } from "../util/auth";
import { getEmbodimentById } from "../api/embodimentManagement";
import { getFeagiSessionInfo } from "../api/feagiSessionManagement";
import Webcam from "./embodiments-code/Webcam";
import DisplayMarkdown from "../util/DisplayMarkdown";
import NavbarBehaviors from "./navbar/NavbarBehaviors";
import SnackbarMessage from "./SnackbarMessage";
import CustomDialog from "./CustomDialog";
import PageLoader from "./PageLoader";
import PlayPhysical from "./PlayPhysical";
import { useTheme } from "@emotion/react";
import handleLeavePlay from "../util/handleLeavePlay";
import PlayBehaviors from "./PlayBehaviors";

const dummyBehaviors = [
  {
    title: "Spin About",
    description:
      "You can spin if you want to! You can leave your friends behind.",
    url: "https://www.example.com",
    payload: { dog: true, cat: false },
    img_url:
      "https://storage.googleapis.com/embodiment_images/61icXqx9LuL._AC_UL640_QL65_-removebg-preview.png",
    img_alt_text: "heyoo",
  },
  {
    title: "Eat An Oak Leaf By The River",
    description: "Woo girl, yah, ",
    url: "https://www.example.com",
    payload: { dog: true, cat: false },
    img_url:
      "https://storage.googleapis.com/embodiment_images/cozmo_looking_right-e1637024712708.webp",
    img_alt_text: "heyoo",
  },
];

const Play = () => {
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const auth = useAuth();
  const accessToken = auth.user.accessToken;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isSessionValid, setIsSessionValid] = useState(null);
  const [behaviorName, setBehaviorName] = useState("");
  const [leavePlayPath, setLeavePlayPath] = useState("");
  // Data from URL
  const { id: embodimentId } = useParams();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const sessionId =
    queryParameters.get("session_id") || queryParameters.get("id");
  const clusterId =
    queryParameters.get("cluster_id") || queryParameters.get("cluster");
  const isFromBV = queryParameters.get("ref") === "brain-visualizer";
  // Embodiments
  const [embodiment, setEmbodiment] = useState({});
  const [embodimentType, setEmbodimentType] = useState(""); // "user-ws-device", "nrs-html-app", "nrs-bt-device"
  const [physicalConnected, setPhysicalConnected] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [instructionsOpen, setInstructionsOpen] = useState(true);
  const [config, setConfig] = useState({});
  const [webcamOpen, setWebcamOpen] = useState(false);
  // const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    if (behaviorName) {
      setMessage("Personality change complete.");
    }
  }, [behaviorName]);

  useEffect(() => {
    async function callFetchEmbodiment() {
      try {
        // Check session validity
        if (!sessionId) {
          setError("URL is missing required session ID.");
          return;
        }
        const sessionRes = await getFeagiSessionInfo(accessToken, sessionId);
        if (sessionRes.data.state === "running") {
          setIsSessionValid(true);
        } else {
          throw new Error("Session is not running: ", sessionRes.data.state);
        }
        // Get embodiment info
        const embodimentRes = await getEmbodimentById(
          accessToken,
          embodimentId
        );
        const data = embodimentRes.data;
        setEmbodiment(data);
        data?.agent_type && setEmbodimentType(data?.agent_type);
        data?.play_notes && setInstructions(data.play_notes);
        const dataConfig = data?.play_obj?.configuration;
        if (
          !dataConfig ||
          (data?.agent_type === "nrs-html-app" && !dataConfig.player_1)
        ) {
          throw new Error("Embodiment is missing play information.");
        }
        setConfig(dataConfig);
      } catch (err) {
        // Handle errors
        console.error(err);
        console.log("message:", err.message);
        if (!isSessionValid) {
          setError(
            "This play session is invalid or expired. Please click Play Select to start fresh."
          );
        } else {
          setError(
            "An error occurred fetching the required info. Please reload, or return to the lab if issues persist."
          );
        }
      } finally {
        setLoading(false);
      }
    }

    accessToken && callFetchEmbodiment();
  }, [accessToken, embodimentId, isSessionValid, sessionId, behaviorName]);

  const handlePlaysClick = () => {
    if (embodimentId) {
      setLeavePlayPath("/play");
    } else {
      window.location.href = "/play";
    }
  };

  // const toggleMute = () => {
  //   setIsMuted(!isMuted);
  // };

  const htmlAppMaker = (player, title, feagiIndex, outputFeagiIndex, port) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          variant="h3"
          sx={{ fontSize: "1.2rem", fontWeight: 500, textAlign: "center" }}
        >
          {title}
        </Typography>
        <iframe
          title={embodimentId}
          sandbox="allow-scripts allow-popups allow-same-origin allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
          allow="cross-origin-isolation"
          src={`${process.env.PUBLIC_URL}/static/${embodimentId}/index.html?ip_address=${sessionId}-feagi.${clusterId}.neurorobotics.studio&port_disabled=true&websocket_url=wss://${sessionId}-feagi.${clusterId}.neurorobotics.studio/p${port}&http_type=HTTPS://&capabilities=input%2Ecamera%2E0%2Efeagi_index%3D${feagiIndex}%7Coutput%2Emotion_control%2E0%2Efeagi_index%3D${outputFeagiIndex}&player=${player}`}
          style={{
            maxWidth: "80vw",
            width:
              embodimentId === "em-ggtoxruz"
                ? "600px"
                : isSmOrSmaller
                ? "250px"
                : "500px",
            height:
              embodimentId === "em-ggtoxruz"
                ? "150px"
                : isSmOrSmaller
                ? "250px"
                : "500px",
            backgroundColor: "white",
            pointerEvents: player === 2 ? "none" : "auto",
          }}
        />
      </Box>
    );
  };

  if (loading) return <PageLoader />;

  if (!embodiment?.embodiment_title && error) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          pt: 3,
          px: { xs: 3, md: 6, lg: 24, xl: 48 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Alert severity="error">{error}</Alert>
        <Button variant="outlined" startIcon={<ArrowBackIcon />} href="/play">
          Play Select
        </Button>
      </Box>
    );
  }

  if (
    (embodimentType === "user-ws-device" ||
      embodimentType === "nrs-bt-device") &&
    !physicalConnected
  ) {
    return (
      <PlayPhysical
        accessToken={accessToken}
        sessionId={sessionId}
        clusterId={clusterId}
        embodiment={embodiment}
        setPhysicalConnected={setPhysicalConnected}
        setError={setError}
        error={error}
      />
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          px: { xs: 3, md: 6, lg: 18, xl: 32 },
          py: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        {/* Error message */}
        {error && <Alert severity="error">{error}</Alert>}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* Play Select return button */}
          <Box sx={{ flex: 1 }}>
            {!isFromBV && (
              <Button
                variant="outlined"
                size="small"
                startIcon={<ArrowBackIcon />}
                title="Return to Play Select"
                onClick={handlePlaysClick}
              >
                Plays
              </Button>
            )}
          </Box>

          {/* Title */}
          <Typography
            sx={{
              flex: 2,
              fontSize: "1.2rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {embodiment.embodiment_title}
          </Typography>

          {/* Placeholder box / mute */}
          {/* <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={toggleMute} sx={{ color: "primary.main" }}>
              {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
          </Box> */}
          <Box sx={{ flex: 1 }}></Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: { xs: "100%", md: "542px" },
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: 2, md: 4 },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: embodimentType === "nrs-html-app" ? "none" : "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              // alignItems: "center",
              // flexWrap: "wrap",
              gap: 3,
            }}
          >
            {/* Play Select return button */}
            {/* <Box sx={{ flex: 1, order: { xs: 2, md: null } }}>
              <Button
                variant="outlined"
                size="small"
                startIcon={<ArrowBackIcon />}
                href="/play"
                title="Return to Play Select"
              >
                Select
              </Button>
            </Box> */}
            {/* Image */}
            {embodimentType !== "nrs-html-app" && embodiment.image_url && (
              <Box
                sx={{
                  flex: { xs: "2 1 100%", md: 2 },
                  order: { xs: 1, md: 2 },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  crossorigin="anonymous"
                  src={embodiment.image_url}
                  alt={embodiment.embodiment_title}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "150px",
                    objectFit: "contain",
                  }}
                />
              </Box>
            )}
            {/* Change personality button */}
            <Box
              sx={{
                flex: 1,
                // order: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              {(embodimentType === "nrs-bt-device" ||
                embodimentType === "user-ws-device") && (
                <NavbarBehaviors
                  accessToken={accessToken}
                  error={error}
                  setError={setError}
                  embodimentId={embodimentId}
                  handleChange={(name) => setBehaviorName(name)}
                />
              )}
            </Box>
          </Box>

          {/* Personality title */}
          {(embodimentType === "nrs-bt-device" ||
            embodimentType === "user-ws-device") && (
            <Box
              sx={{
                flex: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                variant="h3"
                sx={{ fontSize: { xs: "1.2rem", md: "2rem" } }}
              >
                {embodiment.behavior_title
                  ? embodiment.behavior_title
                  : embodiment.embodiment_title
                  ? `Playing with ${embodiment.embodiment_title}`
                  : ""}
              </Typography>
              {/* Personality info button */}
              <IconButton
                onClick={() => setInstructionsOpen(true)}
                disabled={!instructions}
              >
                <InfoIcon />
              </IconButton>
            </Box>
          )}

          {/* Virtual embodiment iframes */}
          {embodimentType === "nrs-html-app" && (
            <Box
              sx={{
                flex: 1,
                // width: "50%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {config?.player_1 != null &&
                htmlAppMaker(
                  1,
                  config.player_1?.label,
                  config.player_1?.capabilities?.input?.camera?.[0]
                    ?.feagi_index,
                  config.player_1?.capabilities?.output?.motion_control?.[0]
                    ?.feagi_index,
                  "9055"
                )}
              {config?.player_2 != null &&
                htmlAppMaker(
                  2,
                  config.player_2?.label,
                  config.player_2?.capabilities?.input?.camera?.[0]
                    ?.feagi_index,
                  config.player_2?.capabilities?.output?.motion_control?.[0]
                    ?.feagi_index,
                  "9056"
                )}
            </Box>
          )}

          {/* Webcam */}
          {config.webcam && (
            <Box
              sx={{
                flex: 1,
                // width: { xs: "100%", md: "50%" },
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: { xs: "center", md: "flex-start" },
                gap: 1,
              }}
            >
              <Typography
                variant="h3"
                sx={{ fontSize: "1.2rem", fontWeight: "500" }}
              >
                Gesture Monitor
                {/* <IconButton
                  size="small"
                  onClick={() => setInstructionsOpen(true)}
                  disabled={!instructions}
                >
                  <InfoIcon />
                </IconButton> */}
              </Typography>
              {webcamOpen ? (
                <Webcam
                  webcamOpen={webcamOpen}
                  setWebcamOpen={setWebcamOpen}
                  setError={setError}
                  configFromDb={config.webcam}
                  isEmbedded={true}
                />
              ) : (
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setWebcamOpen(true)}
                  >
                    Open Webcam
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>

        {/* Behaviors */}
        {(embodimentType === "nrs-bt-device" ||
          embodimentType === "user-ws-device") && (
          <PlayBehaviors accessToken={accessToken} behaviors={dummyBehaviors} />
        )}
      </Box>

      {/* Play instructions dialog */}
      {instructionsOpen && instructions && (
        <CustomDialog
          header=""
          text=""
          isOpen={instructionsOpen}
          handleClose={() => setInstructionsOpen(false)}
          confirmAction={() => setInstructionsOpen(false)}
          richText={<DisplayMarkdown markdownFromDb={instructions} />}
        />
      )}

      {/* Success message */}
      <SnackbarMessage
        message={message}
        setMessage={setMessage}
        severity="success"
      />

      {/* Confirm leave page */}
      {leavePlayPath && (
        <CustomDialog
          header="Leave the current page and end your play session?"
          // text="(No data will be lost.)"
          isOpen={leavePlayPath}
          handleClose={() => setLeavePlayPath("")}
          confirmAction={() =>
            handleLeavePlay(
              auth.user?.accessToken,
              leavePlayPath,
              setLeavePlayPath
            )
          }
          cancelAction={() => setLeavePlayPath("")}
        />
      )}
    </>
  );
};

export default Play;
