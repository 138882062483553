import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../util/auth";
import { getEmbodiments } from "../api/embodimentManagement";
import { getLaunchCriteria } from "../api/experimentManagement";
import SnackbarMessage from "./SnackbarMessage";
import PageLoader from "./PageLoader";
import CustomDialog from "./CustomDialog";
import PlayIcons from "./PlayIcons";
import GetOne from "./GetOne";
import isValidUrl from "../util/isValidUrl";
import { ArrowBack } from "@mui/icons-material";

const Play = () => {
  const auth = useAuth();
  const accessToken = auth?.user?.accessToken;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({
    text: "",
    severity: "info",
  });
  const [disabledMessage, setDisabledMessage] = useState("");
  const [embodiments, setEmbodiments] = useState([]);
  const [selectedEmbodiment, setSelectedEmbodiment] = useState(null);

  // Fetch embodiments & launch criteria on load
  useEffect(() => {
    async function callFetchEmbodiments() {
      try {
        const res = await getEmbodiments({ accessToken, playable: true });
        if (!res?.data) throw new Error("Did not receive data from server");
        setEmbodiments(res.data);
      } catch (err) {
        console.error(err);
        setMessage({
          text: "An error occurred fetching the play embodiments. Please reload, or return to the lab via the navbar if issues persist.",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    }

    async function callGetLaunchCriteria() {
      try {
        const res = await getLaunchCriteria(accessToken);
        if (!res?.data) throw new Error("Did not receive data from server");
        const {
          system_overload,
          max_experiment_count_reached,
          max_concurrent_experiments_reached,
        } = res.data;
        if (system_overload) {
          setDisabledMessage(
            "The system is currently overloaded. Please try again soon."
          );
        } else if (max_experiment_count_reached) {
          setDisabledMessage(
            "You have reached the maximum number of experiments. You can delete one in the lab to clear up Play space."
          );
        } else if (max_concurrent_experiments_reached) {
          setDisabledMessage(
            "You already have an experiment running. Please return to the lab and stop it if you'd like to Play instead."
          );
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (accessToken) {
      callFetchEmbodiments();
      callGetLaunchCriteria();
    }
  }, [accessToken]);

  function cardMaker(emb) {
    return (
      <Card
        key={emb.id}
        sx={{
          maxWidth: { xs: "100%", md: "175px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          cursor: disabledMessage ? "not-allowed" : "pointer",
          pointerEvents: disabledMessage ? "none" : "auto",
        }}
        onClick={() => !disabledMessage && handleSelect(emb)}
      >
        {/* Icons */}
        {emb.play_obj?.requirements?.length > 0 && (
          <CardContent
            sx={{
              py: 1,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 0.5,
              backgroundColor: "rgb(175 194 207 / 30%)",
            }}
          >
            <PlayIcons items={emb.play_obj.requirements} />
            {/* <PlayIcons items={["physical", "windows", "mac", "camera"]} /> */}
          </CardContent>
        )}
        {/* Image */}
        <CardMedia
          component="img"
          height="100"
          image={emb.image_url}
          alt={emb.embodiment_title}
          sx={{ height: "150px", px: 2, py: 1, objectFit: "contain" }}
          crossorigin="anonymous"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Divider
            sx={{ backgroundColor: "background.default", height: "5px" }}
          />
          {/* Title */}
          <Tooltip title={emb.embodiment_title}>
            <CardHeader
              title={emb.embodiment_title}
              titleTypographyProps={{
                sx: {
                  maxWidth: "100%",
                  fontSize: "1.2rem",
                  fontWeight: 500,
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
              sx={{
                py: 1,
                ".MuiCardHeader-content": {
                  width: "100%",
                },
              }}
            />
          </Tooltip>
        </Box>
      </Card>
    );
  }

  function handleSelect(emb) {
    setSelectedEmbodiment(emb);
  }

  function handleConfirm() {
    navigate(`/brain-loading?embodiment=${selectedEmbodiment.embodiment_id}`);
  }

  if (loading) return <PageLoader />;

  return (
    <Box
      sx={{
        mt: 8,
        mx: { xs: 3, md: 6, lg: 24, xl: 48 },
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {/* <SectionHeader title="Plays" size={4} textAlign="center" /> */}
      <Box
        sx={{
          width: "100%",
          padding: "7px",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "5px",
          backgroundColor: "background.paper",
        }}
      >
        <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Button
            variant="outlined"
            size="small"
            startIcon={<ArrowBack />}
            title="Return to Lab"
            href="/lab"
            sx={{ maxWidth: "80px" }}
          >
            Lab
          </Button>
        </Box>
        <Typography
          variant="h4"
          sx={{
            flex: 2,
            textAlign: "center",
          }}
        >
          Plays
        </Typography>
        <Box sx={{ flex: 1 }}></Box>
      </Box>
      {disabledMessage && (
        <Box
          sx={{
            width: "100%",
            my: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Alert severity="warning">{disabledMessage}</Alert>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Button variant="contained" onClick={() => navigate(`/lab`)}>
              Return to Lab
            </Button>
          </Box>
        </Box>
      )}
      {embodiments?.length > 0 && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(auto-fit, minmax(125px, auto))",
              md: "repeat(auto-fit, minmax(175px, auto))",
            },
            justifyContent: "start",
            gap: 3,
          }}
        >
          {embodiments.map((emb) => cardMaker(emb))}
        </Box>
      )}

      {/* Embodiment modal */}
      {selectedEmbodiment && (
        <CustomDialog
          isOpen={selectedEmbodiment}
          handleClose={() => setSelectedEmbodiment(null)}
          confirmAction={handleConfirm}
          cancelAction={() => setSelectedEmbodiment(null)}
          richText={
            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRadius: "5px",
                backgroundColor: "background.paper",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Typography sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
                    {selectedEmbodiment.embodiment_title}
                  </Typography>
                  <Typography sx={{ fontSize: ".8rem", fontWeight: 500 }}>
                    by {selectedEmbodiment.manufacturer}
                  </Typography>
                  {selectedEmbodiment.play_obj?.requirements?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      <PlayIcons
                        items={selectedEmbodiment.play_obj.requirements}
                      />
                    </Box>
                  )}
                  {selectedEmbodiment.play_obj?.platforms?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      <Typography>Supported Platforms:</Typography>{" "}
                      <PlayIcons
                        items={selectedEmbodiment.play_obj.platforms}
                      />
                    </Box>
                  )}
                  {selectedEmbodiment.environment_type === "physical" && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <Typography sx={{ fontWeight: 600 }}>
                        *Robot Required
                      </Typography>
                      {selectedEmbodiment.product_url &&
                        isValidUrl(selectedEmbodiment.product_url) && (
                          <GetOne
                            accessToken={accessToken}
                            embodiment={selectedEmbodiment}
                            size="small"
                          />
                        )}
                    </Box>
                  )}
                </Box>
                <img
                  crossorigin="anonymous"
                  src={selectedEmbodiment.image_url}
                  alt={selectedEmbodiment.embodiment_title}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Typography sx={{ mt: 2, fontWeight: 500 }}>
                {selectedEmbodiment.description}
              </Typography>
            </Box>
          }
        />
      )}

      {/* Info / Error message */}
      {message && (
        <SnackbarMessage
          message={message.text}
          setMessage={setMessage}
          autoHide={12000}
          severity={message.severity}
        />
      )}
    </Box>
  );
};

export default Play;
