import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import lz4 from "lz4js";
import config from "../../config";
import { logger } from "../../util/logger";
import processImageData from "./utils/processImageData";
import cameraCapabilities from "./utils/cameraCapabilities";
import { getStimulationPeriod } from "../../api/feagiApiBurstEngine";
import dataToUint8Array from "../../util/dataToJsonAndUInt8Array";
import Dragger from "../Dragger";
import getGridPoints from "./utils/getGridPoints";
import DrawBorderlines from "./utils/DrawBorderlines";
import DrawGrid from "./utils/DrawGrid";
import updateCapabilitiesControl from "./utils/updateCapabilitiesControl";

const Webcam = ({
  webcamOpen,
  setWebcamOpen,
  setError,
  handleErrorOpen,
  configFromDb,
  isEmbedded,
}) => {
  const capabilitiesRef = useRef(configFromDb || cameraCapabilities);
  // Draw grid lines on webcam feed
  const [gridCoords, setGridCoords] = useState(
    getGridPoints(capabilitiesRef.current)
  );
  const [activationRegions, setActivationRegions] = useState([]);
  // Get session ID
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const sessionId =
    queryParameters.get("id") || queryParameters.get("session_id");
  const clusterId =
    queryParameters.get("cluster") || queryParameters.get("cluster_id");
  // Establish video & websocket variables
  const videoRef = useRef(null);
  const wsRef = useRef(null);
  const streamRef = useRef(null);
  const intervalIdRef = useRef(null);
  const [isWebsocketOpen, setIsWebSocketOpen] = useState(false);
  const [refreshRate, setRefreshRate] = useState(1000 / 30);
  // Take actions based on picture-in-picture state
  // const [pictureInPicture, setPictureInPicture] = useState(false);

  useEffect(() => {
    if (activationRegions.length > 0) {
      const timeoutId = setTimeout(() => {
        setActivationRegions([]);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [activationRegions]);

  useEffect(() => {
    // Get refresh rate from FEAGI
    async function determineRefreshRate() {
      try {
        const feagiRefreshRate = await getStimulationPeriod(
          sessionId,
          clusterId
        );
        if (feagiRefreshRate * 1000 > refreshRate) {
          setRefreshRate(feagiRefreshRate * 1000);
        }
      } catch (err) {
        console.error("Error getting stimulation period:", err);
      }
    }
    // Make websocket connection
    async function connectWebsocket() {
      try {
        wsRef.current = new WebSocket(
          `wss://${sessionId}-feagi.${clusterId}.neurorobotics.studio/p9051`
        );
        wsRef.current.onopen = () => {
          console.log("Websocket open");
          if (
            capabilitiesRef.current &&
            wsRef.current?.readyState === WebSocket.OPEN
          ) {
            const uInt8Array = dataToUint8Array(capabilitiesRef.current);
            const compressedCapabilities = lz4.compress(uInt8Array);
            console.log("sending capabilities", capabilitiesRef.current);
            wsRef.current.send(compressedCapabilities);
          } else {
            console.error(
              "Websocket not open and/or capabilities not found. Capabilities:",
              capabilitiesRef.current
            );
          }
          setIsWebSocketOpen(true);
        };
        if (wsRef.current)
          wsRef.current.onclose = () => setIsWebSocketOpen(false);
        if (wsRef.current)
          wsRef.current.onmessage = (event) => {
            try {
              logger("received message", event.data);
              const parsedData = JSON.parse(event.data);
              const controls = ["modulation_control", "eccentricity_control"];
              if (controls.some((control) => parsedData[control])) {
                controls.forEach((control) => {
                  if (parsedData[control]) {
                    capabilitiesRef.current = updateCapabilitiesControl(
                      capabilitiesRef.current,
                      control,
                      parsedData[control]
                    );
                  }
                });
                setGridCoords(getGridPoints(capabilitiesRef.current));
              }
              if (parsedData.activation_regions) {
                setActivationRegions(parsedData.activation_regions);
              }
              if (parsedData && typeof parsedData.newRefreshRate === "number") {
                logger("new refresh rate:", parsedData.newRefreshRate);
                // const newRate = parsedData.newRefreshRate * 1000;
                // if (newRate !== refreshRate) setRefreshRate(newRate);
              }
            } catch (err) {
              console.error("Error parsing websocket message:", err);
            }
          };
        wsRef.current?.addEventListener("error", (error) => {
          console.error("WebSocket error: ", error);
          handleClose();
          setError(
            "There was an error connecting the webcam. Please reload, or report a bug if the issue persists."
          );
          console.error("setting error open");
          handleErrorOpen && handleErrorOpen();
        });
      } catch (err) {
        console.error("Error connecting websocket:", err);
        handleClose();
        setError(
          "There was an error connecting the webcam. Please reload, or report a bug if the issue persists."
        );
        handleErrorOpen && handleErrorOpen();
      }
    }
    // Call the above functions
    if (sessionId) {
      determineRefreshRate();
      connectWebsocket();
    } else {
      console.log("Unable to get session ID from URL");
    }
    // Close websocket on cleanup
    return () => {
      if (wsRef.current) wsRef.current.close();
    };
    // The below linter ignore is because it wants refreshRate in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    // Turn on webcam, get video data, and send to FEAGI
    if (webcamOpen && isWebsocketOpen) {
      let stream = null;
      const mediaDevices = navigator.mediaDevices;

      mediaDevices
        .getUserMedia({
          video: true,
          // video: {
          //   width: config.webcamWidth,
          //   height: config.webcamHeight,
          // },
        })
        .then((mediaStream) => {
          stream = mediaStream;
          streamRef.current = mediaStream;
          logger(
            "stream settings: ",
            mediaStream?.getVideoTracks()[0]?.getSettings()
          );
          if (videoRef.current) videoRef.current.srcObject = stream;
          videoRef.current?.addEventListener("loadedmetadata", () => {
            videoRef.current?.play();
            intervalIdRef.current = setInterval(() => {
              if (videoRef.current) {
                const canvas = document.createElement("canvas");
                // canvas.width = videoRef.current.videoWidth;
                // canvas.height = videoRef.current.videoHeight;
                canvas.width = config.webcamWidth;
                canvas.height = config.webcamHeight;
                const context = canvas.getContext("2d");

                // Draw video frame onto FEAGI canvas
                context.drawImage(
                  videoRef.current,
                  0,
                  0,
                  canvas.width,
                  canvas.height
                );

                // Store canvas data in array
                const clampedArray = context.getImageData(
                  0,
                  0,
                  canvas.width,
                  canvas.height
                ).data;

                // Create object (incl. new array with alpha values removed for size reduction)
                // const feagiData = {
                //   vision: processImageData(clampedArray),
                //   vision_size: [config.webcamWidth, config.webcamHeight],
                //   device: "webcam",
                // };
                // Remove alpha values to reduce size
                const feagiData = processImageData(
                  clampedArray,
                  canvas.width,
                  canvas.height
                );
                // Convert to JSON & UInt8Array & compress (lz4 requires uint8array)
                // const uInt8Array = dataToUint8Array(feagiData);
                const uInt8Array = new Uint8ClampedArray(feagiData);

                const compressed = lz4.compress(uInt8Array);

                // Send data through websocket
                // wsRef.current?.send(compressed);
                wsRef.current?.send(compressed);
              }
            }, refreshRate);
          });
        })
        .catch((error) => {
          alert(error);
          handleClose();
        });

      // When the `webcamOpen` prop changes to false, stop the video stream and clear the interval
      return () => {
        handleClose();
      };
    } else {
      console.log(
        "Websocket connection not open. Websocket (should exist):",
        wsRef.current
      );
      console.log(
        `Websocket readyState (should be 1): ${wsRef.current?.readyState}. webcamOpen variable (should be true): ${webcamOpen}`
      );
    }
    // The below linter ignore is because it wants handleClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamOpen, isWebsocketOpen, refreshRate]);

  const handleClose = () => {
    console.log("Shutting off video & stream sending");
    // Stop and clear the video stream
    if (videoRef.current) {
      console.log("Stopping video stream");
      videoRef.current.pause();
      videoRef.current.srcObject = null;
    }
    // Stop all tracks of the stream to turn off the camera
    if (streamRef.current) {
      console.log("Stopping stream tracks");
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
    // Clear the interval
    if (intervalIdRef.current) {
      console.log("Clearing sending interval");
      clearInterval(intervalIdRef);
    }
    // Setting webcamOpen to false
    console.log("Setting webcamOpen to false");
    setWebcamOpen(false);
  };

  // Track picture-in-picture close event from pip window itself
  // useEffect(() => {
  //   const videoElement = videoRef.current;

  //   const onLeavePiP = () => {
  //     // Timeout to avoid split second show of "picture-in-picture" backward text
  //     setTimeout(() => setPictureInPicture(false), 200);
  //   };

  //   if (videoElement) {
  //     videoElement.addEventListener("leavepictureinpicture", onLeavePiP);
  //   }

  //   return () => {
  //     if (videoElement) {
  //       videoElement.removeEventListener("leavepictureinpicture", onLeavePiP);
  //     }
  //   };
  // }, []);

  // Handle picture-in-picture click to open/close
  // const handlePiP = async () => {
  //   if (videoRef.current) {
  //     try {
  //       if (document.pictureInPictureElement) {
  //         await document.exitPictureInPicture();
  //       } else if (
  //         document.pictureInPictureEnabled &&
  //         !videoRef.current.disablePictureInPicture
  //       ) {
  //         await videoRef.current.requestPictureInPicture();
  //         setPictureInPicture(true);
  //       }
  //     } catch (error) {
  //       console.error("Picture-in-Picture Error:", error);
  //       setPictureInPicture(false);
  //     }
  //   }
  // };

  const video = (
    <video
      muted
      playsInline
      ref={videoRef}
      style={{
        height: "100%",
        width: "100%",
        objectFit: "cover",
        transform: "scaleX(-1)", // horizontal flip 🐟💦
        border: isEmbedded ? "1px solid lightgrey" : null,
      }}
    >
      Webcam display pending or failed.
    </video>
  );

  return (
    <>
      {webcamOpen &&
        (isEmbedded ? (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                aspectRatio: "1/1",
                height: "100%",
                // width: "100%",
                maxHeight: { xs: "35vh", md: "50vh" },
                maxWidth: "90vw",
              }}
            >
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  zIndex: 100,
                  top: 8,
                  right: 8,
                  color: "white",
                  backgroundColor: "background.default",
                  "&:hover": { backgroundColor: "background.dark" },
                }}
              >
                <CloseIcon />
              </IconButton>
              {video}
              {gridCoords && <DrawGrid gridCoords={gridCoords} />}
              {activationRegions?.length > 0 && (
                <DrawBorderlines
                  gridCoords={gridCoords}
                  regions={activationRegions}
                />
              )}
            </Box>
          </Box>
        ) : (
          <Dragger handle=".dragHandle">
            <Card
              sx={{
                position: "absolute",
                border: "1px solid black",
                minWidth: "150px",
                minHeight: "150px",
                // ...(pictureInPicture && {
                //   minHeight: "35px",
                //   height: "35px",
                // }),
              }}
            >
              <div
                className="dragHandle"
                style={{
                  padding: "2px",
                  paddingLeft: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#313131",
                  cursor: "move",
                }}
              >
                <Typography fontWeight="500">Webcam</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* <Tooltip
                    title={
                      pictureInPicture
                        ? "Close Picture-in-Picture"
                        : "Open Picture-in-Picture"
                    }
                  >
                    <span>
                      <IconButton
                        onClick={handlePiP}
                        onMouseDown={(e) => e.stopPropagation()}
                        sx={{ padding: "4px" }}
                      >
                        <PictureInPicture
                          color={pictureInPicture ? "primary.main" : "white"}
                        />
                      </IconButton>
                    </span>
                  </Tooltip> */}
                  <IconButton
                    onClick={handleClose}
                    onMouseDown={(e) => e.stopPropagation()}
                    sx={{ padding: "4px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </div>
              <Box
                sx={{
                  position: "relative",
                  aspectRatio: "1/1",
                  maxHeight: "150px",
                  // height: "100%",
                  // width: "100%",
                }}
              >
                {video}
                {gridCoords && <DrawGrid gridCoords={gridCoords} />}
                {activationRegions?.length > 0 && (
                  <DrawBorderlines
                    gridCoords={gridCoords}
                    regions={activationRegions}
                  />
                )}
              </Box>
            </Card>
          </Dragger>
        ))}
    </>
  );
};

export default Webcam;
