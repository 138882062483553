import { useState } from "react";
import { Paper, Box, DialogContentText, Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { deployGenome } from "../api/feagiSessionManagement";
import CustomDialog from "./CustomDialog";
// import { logger } from "../util/logger";
import { useLocation } from "react-router-dom";
// import { updateExperiment } from "../api/experimentManagement";

export default function CarouselComplex({
  accessToken,
  error,
  setError,
  items,
  handleBehaviorsClose,
  handleChange,
  experiment,
}) {
  // Get session ID
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const sessionId =
    queryParameters.get("id") || queryParameters.get("session_id");
  // Other variables
  const [carouselItems, setCarouselItems] = useState(items);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedGenomeId, setSelectedGenomeId] = useState(null);
  const [selectedGenomeTitle, setSelectedGenomeTitle] = useState("");
  const gapPercent = 3;
  const itemWidth = 100 / items.length;

  const goToNextSlide = () => {
    // Shift the array to the left
    setCarouselItems((prevItems) => {
      const nextItems = [...prevItems.slice(1), prevItems[0]];
      return nextItems;
    });
  };

  const goToPrevSlide = () => {
    // Shift the array to the right
    setCarouselItems((prevItems) => {
      const lastItem = prevItems[prevItems.length - 1];
      const nextItems = [lastItem, ...prevItems.slice(0, -1)];
      return nextItems;
    });
  };

  const handleSelect = (id, title) => {
    setSelectedGenomeId(id);
    setSelectedGenomeTitle(title);
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setSelectedGenomeTitle("");
    setSelectedGenomeId(null);
  };

  const handleConfirmationAgree = async () => {
    try {
      if (!sessionId || !accessToken || !selectedGenomeId) {
        throw new Error("Missing a required argument.");
      }
      // const res = await updateExperiment(
      //   accessToken,
      //   experiment.experiment_id,
      //   experiment.experiment_title,
      //   experiment.experiment_description,
      //   selectedGenomeId
      // );
      deployGenome(accessToken, sessionId, selectedGenomeId);
      if (handleChange) handleChange(selectedGenomeTitle);
      handleConfirmationClose();
      handleBehaviorsClose();
    } catch (err) {
      console.error(err);
      setError(
        "Sorry, an error occurred changing the personality. If you continue to experience issues, please reload the page."
      );
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <IconButton onClick={goToPrevSlide}>
        <KeyboardArrowLeft sx={{ fontSize: { xs: "1.5rem", md: "3rem" } }} />
      </IconButton>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
      >
        <Box
          style={{
            display: "flex",
            justifyContent: `${carouselItems.length < 3 ? "center" : ""}`,
            gap: `${gapPercent}%`,
            width: `${itemWidth * carouselItems.length}%`,
            // transform: "translate3d(-75%, 0px, 0px)",
            // transitionDuration: "500ms",
          }}
        >
          {carouselItems.map((item, index) => {
            return (
              <Paper
                key={item.id + index}
                elevation={4}
                sx={{
                  minWidth: "125px",
                  width: `${100 / 3 - gapPercent}%`, // Each item should take up an equal percentage of the carousel width, minus any gap
                  height: "11rem",
                  flexShrink: 0,
                  transition: "all 1s",
                  cursor: "pointer",
                }}
                onClick={() => handleSelect(item.id, item.title)}
              >
                {item.content}
              </Paper>
            );
          })}
        </Box>
      </Box>

      <IconButton onClick={goToNextSlide}>
        <KeyboardArrowRight sx={{ fontSize: { xs: "1.5rem", md: "3rem" } }} />
      </IconButton>
      {/* Confirmation dialog */}
      <CustomDialog
        header="Replace your current genome?"
        text=""
        richText={
          <DialogContentText id="alert-dialog-description">
            This will replace your robot's personality with your selection.{" "}
            {!handleChange && "Your experiment genome will change."}
            {error && (
              <Alert severity="error" style={{ marginTop: "20px" }}>
                {error}
              </Alert>
            )}
          </DialogContentText>
        }
        isOpen={confirmationOpen}
        handleClose={handleConfirmationClose}
        confirmAction={handleConfirmationAgree}
        cancelAction={handleConfirmationClose}
      />
    </Box>
  );
}
