import { Box, Typography } from "@mui/material";

const PlayBehaviors = ({ accessToken, behaviors }) => {
  const handleClick = async (url, payload) => {
    console.log(`fetch at ${url} with payload ${payload}`);
  };

  return (
    <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography
        sx={{ textAlign: "center", fontSize: "1.2rem", fontWeight: "500" }}
      >
        Behaviors
      </Typography>
      <Typography>Select a behavior to watch it in action!</Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: 2,
        }}
      >
        {behaviors.map((behavior) => (
          <Box
            key={behavior.title}
            onClick={() => handleClick(behavior.url, behavior.payload)}
            sx={{
              width: { xs: "100px", md: "125px" },
              height: { xs: "140px", md: "165px" },
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
              border: 1,
              borderRadius: "5px",
              borderColor: "background.light",
              backgroundColor: "background.default",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "background.dark",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: ".65rem", md: ".8rem" },
                fontWeight: "600",
                wordBreak: "break-word",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
              }}
            >
              {behavior.title}
            </Typography>
            <img
              src={behavior.img_url}
              alt={behavior.img_alt_text}
              crossOrigin="anonymous"
              style={{ maxWidth: "100%", objectFit: "contain" }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PlayBehaviors;
