import { Link } from "react-router-dom";
// import { useAuth } from "../../util/auth";
import { Tab, Tabs } from "@mui/material";

function SettingsNav(props) {
  // const auth = useAuth();
  // const authProviders = auth.user.providers;
  return (
    <Tabs
      value={props.activeKey}
      indicatorColor="primary"
      textColor="primary"
      centered={true}
    >
      <Tab
        component={Link}
        to="/settings/profile"
        label="Profile"
        value="profile"
      />
      <Tab
        component={Link}
        to="/settings/account"
        label="Account"
        value="account"
      />
      {/* {authProviders.includes("password") && (
        <Tab
          component={Link}
          to="/settings/password"
          label="Password"
          value="password"
        />
      )} */}

      {/* <Tab
        component={Link}
        to="/settings/billing"
        label="Billing"
        value="billing"
      /> */}
      {/* <Tab
        component={Link}
        to="/settings/robots"
        label="Robots"
        value="robots"
      /> */}
    </Tabs>
  );
}

export default SettingsNav;
