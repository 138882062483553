import { Box, Typography } from "@mui/material";
import SlideUpBox from "../../../util/SlideUpBox";

const Capabilities = () => {
  return (
    <SlideUpBox>
      <Box
        sx={{
          zIndex: 10,
          mt: 4,
          display: "flex",
          flexDirection: "column",
          gap: { xs: 2, md: 4 },
          color: "accents.light",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem" },
            fontWeight: "700",
            color: "white",
          }}
        >
          What can I do with it?
        </Typography>
        <Typography sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}>
          <Typography
            component="span"
            color="primary"
            sx={{
              fontSize: { xs: "1rem", md: "1.3rem" },
              fontWeight: "600",
            }}
          >
            What can you imagine?
          </Typography>{" "}
          Go beyond image or text generation, and join a community developing
          true Artificial General Intelligence.
        </Typography>{" "}
        <Typography sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}>
          The flexible brain-inspired structure enables you to literally develop
          AI --{" "}
          <Typography
            component="span"
            sx={{
              fontSize: { xs: "1rem", md: "1.3rem" },
              color: "white",
              fontWeight: "600",
            }}
          >
            no prior skills required
          </Typography>
          . We’ll teach you, and it won’t take long!
        </Typography>{" "}
        {/* <Typography sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}>
        Further, it’s not limited to your computer screen -- it can be used to
        create{" "}
        <Typography
          component="span"
          sx={{
            fontSize: { xs: "1rem", md: "1.3rem" },
            color: "white",
            fontWeight: "600",
          }}
        >
          both virtual and robotic behaviors
        </Typography>
        , from amateur to industrial levels.
      </Typography> */}
        {/* <Box sx={{ width: "100%", border: "1px solid white" }}>
        <video
          crossorigin="anonymous"
          muted={true}
          playsInline={true}
          autoPlay={true}
          loop={true}
          controls={false}
          preload="auto"
          style={{
            maxWidth: "100%",
            objectFit: "contain",
            borderRadius: "5px",
          }}
        >
          <source
            // src={`https://storage.googleapis.com/nrs_demos/nrs-trailer.mp4#t=0.001`}
            type="video/mp4"
          />
        </video>
      </Box> */}
      </Box>
    </SlideUpBox>
  );
};

export default Capabilities;
