import { useEffect, useState } from "react";
import { Button, CircularProgress, useTheme } from "@mui/material";
import { useAuth } from "../../util/auth";
import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

function AuthSocial(props) {
  const auth = useAuth();
  const theme = useTheme();
  const [pending, setPending] = useState(false); // Pending spinner INSIDE button
  const [lastUsed, setLastUsed] = useState(null);

  const providerDisplayNames = {
    // google: "Google",
    // facebook: "Facebook",
    // twitter: "Twitter",
    github: "GitHub",
  };

  const onSigninWithProvider = async (provider) => {
    props.setIsSocial(true);
    setPending(provider);
    try {
      await auth.signinWithProvider(provider);
      localStorage.setItem("lastUsedAuthProvider", provider);
      setPending(false);
      // props.onAuth(user, true);
    } catch (error) {
      setPending(false);
      props.onError(error.message);
    }
  };

  // Get value of last used auth provider
  useEffect(() => {
    if (props.showLastUsed) {
      const lastUsed = window.localStorage.getItem("lastUsedAuthProvider");
      if (lastUsed) {
        setLastUsed(lastUsed);
      }
    }
  }, [props.showLastUsed]);

  return (
    <div>
      {props.providers.map((provider) => (
        <Button
          variant="outlined"
          size="large"
          type="submit"
          disabled={pending === provider}
          fullWidth={true}
          onClick={() => {
            onSigninWithProvider(provider);
          }}
          key={provider}
          sx={{
            position: "relative",
            "&:not(:last-child)": {
              marginBottom: theme.spacing(1),
            },
          }}
        >
          <div
            style={{
              position: "absolute",
              alignItems: "center",
              display: "inline-flex",
              justifyContent: "center",
              width: "1.5em",
              height: "1.5em",
              left: "0.5em",
              "& > img": {
                display: "block",
                width: "100%",
              },
            }}
          >
            {/* <img
              src={`https://uploads.divjoy.com/icon-${provider}.svg`}
              alt={providerDisplayNames[provider]}
            /> */}
            {/* {provider.toLowerCase() === "google" ? (
              <GoogleIcon />
            ) : ( */}
            <GitHubIcon />
            {/* )} */}
          </div>
          {/* e.g. "Sign in with Github" */}
          {pending !== provider && (
            <span>
              {props.buttonAction} with {providerDisplayNames[provider]}
            </span>
          )}

          {pending === provider && <CircularProgress size={28} />}

          {provider === lastUsed && (
            <div
              style={{
                position: "absolute",
                top: "-6px",
                right: "-6px",
                fontSize: "13px",
                lineHeight: 1,
                textTransform: "initial",
                padding: "3px 5px",
                borderRadius: "7px",
                color: "white",
                backgroundColor: theme.palette.accents.main,
                opacity: 0.8,
              }}
            >
              Last used
            </div>
          )}
        </Button>
      ))}
    </div>
  );
}

export default AuthSocial;
