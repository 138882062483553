import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";

// get environments, filter if desired
// example response:
// {
//   "count": 2,
//   "results": [
//     {
//       "environment_id": "en-idw",
//       "created_at": "2023-07-18T13:21:17.414000",
//       "updated_at": "2023-07-18T13:21:17.414000",
//       "environment_title": "Physical",
//       "description": "The physical world",
//       "creator_display_name": "27551"
//     },
//     {
//       "environment_id": "en-yku",
//       "created_at": "2023-07-18T13:22:28.817000",
//       "updated_at": "2023-07-18T13:22:28.817000",
//       "environment_title": "Virtual",
//       "description": "Simulated environments",
//       "creator_display_name": "27551"
//     }
//   ]
// }
export async function getEnvironments(
  accessToken,
  skip = 0,
  limit = 10,
  environmentId = "",
  createdBy = ""
) {
  try {
    // Validate args
    apiValidateArguments({ accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/environments/query?skip=${skip}&limit=${limit}`,
      "POST",
      {
        environment_id: environmentId,
        created_by: createdBy,
      },
      { Authorization: accessToken }
    );

    logger("Response in getEnvironments function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getEnvironments function:", error);
    throw error;
  }
}

// get all environments with ids
// export async function getEnvironmentTypes(accessToken) {
//   try {
//     // Validate args
//     apiValidateArguments({ accessToken });

//     // Return custom response data
//     const response = await apiRequestExternal(
//       `${process.env.REACT_APP_COMPOSER_URL}/v1/auth/user/environments/types`,
//       "GET",
//       null,
//       { Authorization: accessToken }
//     );

//     logger("Response in getEnvironmentTypes function:", response);
//     return response;

//     // Handle errors
//   } catch (error) {
//     console.error("Error in getEnvironmentTypes function:", error);
//     throw error;
//   }
// }
