import Tilt from "react-parallax-tilt";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

const TiltCard = ({
  clickAction,
  image,
  text,
  pulse,
  isSmall,
  disabled = false,
}) => {
  const theme = useTheme();
  const isColumn = useMediaQuery(theme.breakpoints.down("md"));
  const isColumnNotSmall = isColumn && !isSmall;

  return (
    <Box
      onClick={disabled ? null : clickAction}
      title={disabled ? "Stop any running experiment first" : null}
      sx={{
        width: "100%",
        maxWidth: isColumnNotSmall ? "500px" : isSmall ? "200px" : "100%",
        height: "1000px",
        maxHeight: isSmall ? "150px" : "60vh",
        my: isColumnNotSmall ? 0 : 2,
        flex: 1,
        borderRadius: "5px",
        cursor: disabled ? "not-allowed" : "pointer",
        pointerEvents: "auto",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <Box
        sx={{
          width: "100%",
          borderRadius: "5px",
          animation: pulse ? "pulse 2s ease-in-out 3" : null,
          "@keyframes pulse": pulse
            ? {
                "0%, 100%": {
                  boxShadow: `0 0 8px 0 ${theme.palette.primary.main}`,
                },
                "50%": {
                  boxShadow: "rgb(114 158 195 / 60%) 0px 0px 15px 5px",
                },
              }
            : null,
        }}
      >
        <Tilt
          tiltMaxAngleX={2}
          tiltMaxAngleY={2}
          style={{
            width: "100%",
            backgroundColor: "#222628",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            alignItems: "center",
            borderRadius: "5px",
          }}
        >
          <img
            src={image}
            alt="bg"
            style={{
              width: "100%",
              maxWidth: "400px",
              height: "auto",
              maxHeight: isColumnNotSmall
                ? "25vh"
                : isSmall
                ? "100px"
                : "400px",
              margin: isSmall ? "10px 0" : "5px 0",
              padding: isSmall ? "5px 10px" : "10px 20px",
              objectFit: "contain",
            }}
          />
          <Typography
            sx={{
              width: "100%",
              py: isColumnNotSmall ? 2 : isSmall ? 1 : 3,
              textAlign: "center",
              fontSize: isColumnNotSmall
                ? "1.2rem"
                : isColumn
                ? "1rem"
                : "1.4rem",
              fontWeight: "600",
              bgcolor: "#101214bd",
            }}
          >
            {text}
          </Typography>
        </Tilt>
      </Box>
    </Box>
  );
};

export default TiltCard;
