import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { requireAuth, useAuth } from "../util/auth";
import { getUserProfile } from "../api/userManagement";
import UserBadges from "./UserBadges";
import formatDate from "../util/formatDate";

function UserProfile(props) {
  const auth = useAuth();
  const accessToken = auth?.user?.accessToken;
  const location = useLocation();
  const pathName = location?.pathname;
  const displayName = pathName.split("/").pop();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [userObject, setUserObject] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (!displayName) {
          setError("No display name found in URL");
          return;
        }
        const { data } = await getUserProfile(accessToken, displayName);
        if (!data) {
          console.log("Data from server is empty.");
          setError("User not found.");
          return;
        }
        setUserObject(data);
      } catch (err) {
        console.error("Error fetching user profile:", err);
        setError("An error occurred fetching the user profile. Please reload.");
      } finally {
        setLoading(false);
      }
    };

    accessToken && fetchUserProfile();
  }, [accessToken, displayName]);

  return (
    <Container
      maxWidth="sm"
      style={{
        marginTop: "50px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: userObject ? "flex-start" : "center",
        alignItems: "center",
      }}
    >
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {!loading && userObject && (
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 4 }}>
          <Grid item={true} xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src={userObject.avatar.img_url}
                alt={userObject.avatar.img_alt_text || "Avatar"}
                sx={{
                  width: 80,
                  height: 80,
                  padding: 1.5,
                  mr: 2,
                  fontSize: "2rem",
                  backgroundColor: "background.paper",
                  color: "white",
                }}
              >
                ?
              </Avatar>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {userObject.display_name}
                </Typography>
                {userObject.created_at && (
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "0.8rem", color: "text.secondary" }}
                  >
                    Joined {formatDate(userObject.created_at)}
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography sx={{ mt: 2, fontSize: ".95rem" }}>
              {userObject.description}
            </Typography>
          </Grid>

          {/* Badges */}
          {userObject && userObject.earned_badges && (
            <UserBadges badges={userObject.earned_badges} />
          )}
        </Box>
      )}
    </Container>
  );
}

export default requireAuth(UserProfile);
