import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import DOMPurify from "dompurify";

const DisplayMarkdown = ({ markdownFromDb }) => {
  const sanitizedMarkdown = DOMPurify.sanitize(markdownFromDb);

  // Custom renderer for images
  const renderers = {
    img: ({ src, alt, title }) => {
      return (
        <img
          src={src}
          alt={alt}
          crossorigin="anonymous"
          style={{ maxWidth: "100%" }}
        />
      );
    },
  };

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={renderers}>
      {sanitizedMarkdown}
    </ReactMarkdown>
  );
};

export default DisplayMarkdown;
