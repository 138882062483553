import { Alert, Box, Button, Typography } from "@mui/material";
import MagicLink from "./MagicLink";
import { useEffect, useState } from "react";
import DisplayMarkdown from "../util/DisplayMarkdown";
import { getAgents } from "../api/feagiAgent";
import PageLoader from "./PageLoader";
import PlayBluetooth from "./PlayBluetooth";

const PlayPhysical = ({
  accessToken,
  sessionId,
  clusterId,
  embodiment,
  setPhysicalConnected,
  error,
  setError,
}) => {
  const [loading, setLoading] = useState(true);
  const CHECK_INTERVAL = 30000;
  const [link, setLink] = useState("");
  const [naw, setNaw] = useState("");
  const [isEmbodimentConnected, setIsEmbodimentConnected] = useState(false);

  console.log("play embodiment", embodiment);

  // Call on page load & periodically to check if connected
  useEffect(() => {
    async function callGetAgents() {
      try {
        const res = await getAgents(sessionId, clusterId);
        if (res?.data?.includes(embodiment.controller_id)) {
          setPhysicalConnected(true);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    if (sessionId && clusterId && embodiment?.controller_id) {
      callGetAgents();
      const intervalId = setInterval(callGetAgents, CHECK_INTERVAL);
      return () => clearInterval(intervalId);
    } else {
      console.error(
        `Missing required props for PlayPhysical. Session ID: ${sessionId}, Cluster ID: ${clusterId}, Controller ID: ${embodiment?.controller_id}`
      );
      setError(
        "Unable to fetch required internal information. Please reload, or restart play if issues persist."
      );
      setLoading(false);
    }
  }, [
    sessionId,
    clusterId,
    embodiment?.controller_id,
    setPhysicalConnected,
    loading,
    setError,
  ]);

  // Check connection when user clicks button
  const handleConnectClaim = async () => {
    try {
      setNaw("");
      const res = await getAgents(sessionId, clusterId);
      if (res?.data?.includes(embodiment.controller_id)) {
        setPhysicalConnected(true);
      } else {
        setNaw(
          "It seems your robot is not connected. Please review the instructions and retry."
        );
      }
    } catch (err) {
      console.error(err);
      setError(
        "Sorry, there was an error confirming the connection. Please reload if issues persists."
      );
    }
  };

  if (loading) return <PageLoader />;

  return (
    <Box
      sx={{
        width: "100%",
        my: 8,
        px: { xs: 2, sm: 4, md: 8, xl: 40 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}

      {embodiment?.image_url && (
        <Box sx={{ maxWidth: "200px" }}>
          <img
            src={embodiment.image_url}
            alt={embodiment.embodiment_title}
            crossorigin="anonymous"
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Box>
      )}

      <Typography variant="h4">Connect Your Robot</Typography>

      {embodiment.agent_type === "user-ws-device" && (
        <MagicLink
          accessToken={accessToken}
          embodimentId={embodiment.embodiment_id}
          setError={setError}
          link={link}
          setLink={setLink}
        />
      )}

      {embodiment.agent_type === "nrs-bt-device" && (
        <PlayBluetooth
          isEmbodimentConnected={isEmbodimentConnected}
          setIsEmbodimentConnected={setIsEmbodimentConnected}
        />
      )}

      <Box
        sx={{
          p: "8px 24px",
          borderRadius: "10px",
          border: "1px solid #464646",
          backgroundColor: "#383a3b3b",
        }}
      >
        <DisplayMarkdown markdownFromDb={embodiment.connectivity_notes} />
      </Box>

      {embodiment.agent_type === "user-ws-device" && (
        <>
          <Typography variant="h4">All Done?</Typography>

          {naw && <Alert severity="warning">{naw}</Alert>}

          <Button variant="contained" onClick={handleConnectClaim}>
            Test Connection
          </Button>
        </>
      )}
    </Box>
  );
};

export default PlayPhysical;
