import { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Switch,
  Tooltip,
} from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { StyledPopover, StyledButton } from "../../styles/NavbarDropdownStyles";
// import { useAuth } from "../../util/auth";
// import logCurrentTimestamp from "../../util/logCurrentTime";
import getBrowserInfo from "../../util/getBrowserInfo";
import NewExperimentEmbodiment from "../new-experiment/NewExperimentEmbodiment";
import NavbarEmbodimentInfo from "./embodiment/EmbodimentInfo";
import NavbarEmbodimentGuidance from "./embodiment/EmbodimentGuidance";
import EmbodimentInstructions from "./embodiment/EmbodimentInstructions";
import EmbodimentInstructionsBluetooth from "./embodiment/EmbodimentInstructionsBluetooth";
import Microbit from "../embodiments-code/Microbit";
// import GodotEmbodiment from "../embodiments-code/GodotEmbodiment";
import CustomDialog from "../CustomDialog";
import MagicLink from "../MagicLink";
import { getSessionFeagiStatus } from "../../api/feagiSessionManagement";
import { replaceEmbodiment } from "../../api/experimentManagement";

const NavbarEmbodiment = ({
  experiment,
  accessToken,
  setError,
  setMessage,
  sessionId,
  clusterId,
  setRefetchExperiment,
  isEmbodimentConnected,
  setIsEmbodimentConnected,
  isMicrophoneChecked,
  setIsMicrophoneChecked,
  screenCaptureOpen,
  setScreenCaptureOpen,
  setEmbodimentFitness,
  awaitAugment,
  isSmOrSmaller,
  ...props
}) => {
  // const auth = useAuth();
  // Entire dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "complex-popover" : undefined;
  // Embodiment info & guidance
  const [embodimentInfoOpen, setEmbodimentInfoOpen] = useState(false); // embodiment info card
  const [integrationGuidanceOpen, setIntegrationGuidanceOpen] = useState(false); // how embodiment works with FEAGI
  // Replace embodiment
  const [embodimentReplaceOpen, setEmbodimentReplaceOpen] = useState(false); // embodiment replace dialog
  const [replaceConfirmOpen, setReplaceConfirmOpen] = useState(false); // confirm embodiment replace
  const [newEmbodimentId, setNewEmbodimentId] = useState(null); // new embodiment id
  const [newEmbodimentTitle, setNewEmbodimentTitle] = useState(null); // new embodiment title
  // Websocket user devices
  const [wsInstructionsOpen, setWsInstructionsOpen] = useState(false);
  const [link, setLink] = useState(""); // magic link
  const [isUserWs, setIsUserWs] = useState(null);
  const [isHtml, setIsHtml] = useState(null);
  const [isBt, setIsBt] = useState(null);
  // Bluetooth devices
  const [btInstructionsOpen, setBtInstructionsOpen] = useState(false);
  const [isBluetoothOpen, setIsBluetoothOpen] = useState(false);
  // Separate browser window for HTML game types
  const [userBrowser, setUserBrowser] = useState(null);
  // const embodimentUrl = `${window.location.protocol}//${window.location.host}/embodiment?session_id=${sessionId}&cluster_id=${clusterId}`;
  const [linkAlertOpen, setLinkAlertOpen] = useState(false);
  // Handle case virtual game open in another tab
  // const [separateEmbodimentWindowOpen, setSeparateEmbodimentWindowOpen] = useState(false);
  // const stopInterval = useRef(null);
  // const allowableDelay = 20000;

  // Fetch browser info
  useEffect(() => {
    async function fetchBrowserInfo() {
      const { browser } = await getBrowserInfo();
      setUserBrowser(browser);
      // setIsChrome(browser === "Chrome");
      // setIsBreakingCombo(isUsingIntelMac && browser !== "Safari");
    }

    fetchBrowserInfo();
  }, []);

  // Set embodiment type
  useEffect(() => {
    if (experiment) {
      setIsUserWs(experiment.agent_type === "user-ws-device");
      setIsHtml(experiment.agent_type === "nrs-html-app");
      setIsBt(experiment.agent_type === "nrs-bt-device");
      // note: "no-agent" type is also possible
    }
  }, [experiment]);

  // Open embodiment dropdown
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close embodiment dropdown
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Replace embodiment
  const handleReplaceEmbodiment = async () => {
    try {
      setError("");
      setMessage("");
      setReplaceConfirmOpen(false);
      await replaceEmbodiment(
        accessToken,
        sessionId,
        experiment?.experiment_id,
        newEmbodimentId,
        newEmbodimentTitle
      );
      setEmbodimentReplaceOpen(false);
      setMessage(`Embodiment successfully replaced.`);
      setRefetchExperiment((prev) => !prev);
    } catch (err) {
      console.error("Error replacing embodiment:", err);
      setMessage("");
      setError(
        "Sorry, there was an error replacing your embodiment. Please reload if issues persist."
      );
    }
  };

  // Show Godot embodiment connection on state change
  useEffect(() => {
    setIsEmbodimentConnected(props.godotEmbodimentOpen);
  }, [props.godotEmbodimentOpen, setIsEmbodimentConnected]);

  // Handle websocket switch click
  const handleWsDeviceClick = () => {
    if (!isEmbodimentConnected) {
      setWsInstructionsOpen(true);
    } else {
      setIsEmbodimentConnected(false);
    }
  };

  // Handle bluetooth switch click
  const handleBtDeviceClick = () => {
    if (!isEmbodimentConnected) {
      setBtInstructionsOpen(true);
    } else {
      setIsEmbodimentConnected(false);
    }
  };

  // Show user prompt to open in new tab if breaking combo
  const handleBreakingEmbodimentClick = () => {
    handleClose();
    // if (separateEmbodimentWindowOpen) {
    //   setSeparateEmbodimentWindowOpen(false);
    //   setIsEmbodimentConnected(false);
    //   // if (stopInterval.current) {
    //   //   stopInterval.current();
    //   // } else {
    //   //   console.log("stopInterval not defined");
    //   // }
    // } else {
    setLinkAlertOpen(true);
    // stopInterval.current = startInterval();
    // }
  };

  // Interval to check FEAGI session & store game open state
  // function startInterval() {
  //   try {
  //     // Initial set so we aren't chicken & egg
  //     const sessionTime = new Date().getTime();
  //     console.log("setting item");
  //     localStorage.setItem(
  //       "sessionOpen",
  //       JSON.stringify({ sessionId, sessionTime })
  //     );
  //     // Clear any old gameOpen data
  //     localStorage.removeItem("gameOpen");

  //     const interval = setInterval(() => {
  //       // Function to clear interval, turn off switch, & remove storage
  //       const shutdown = () => {
  //         // props.setGodotEmbodimentOpen(false);
  //         setIsEmbodimentConnected(false);
  //         setSeparateEmbodimentWindowOpen(false);
  //         clearInterval(interval);
  //         // localStorage.removeItem("sessionOpen");
  //       };
  //       // Get data from storage
  //       const sessionTime = new Date().getTime();
  //       const gameOpenData = JSON.parse(localStorage.getItem("gameOpen"));
  //       // Stop if data not found
  //       if (!gameOpenData) return;
  //       // Parse data
  //       const { gameTime, sessionId: storedSessionId } = gameOpenData;
  //       console.log("gametime, sessionId:", gameTime, storedSessionId);
  //       // Stop the interval if no session time found, or session over time
  //       if (
  //         !gameTime ||
  //         storedSessionId !== sessionId ||
  //         sessionTime - gameTime > allowableDelay
  //       ) {
  //         console.log(
  //           "No game time found or game over time. Turning off switch."
  //         );
  //         shutdown();
  //         // Else set session open time
  //       } else {
  //         // props.setGodotEmbodimentOpen(true);
  //         setIsEmbodimentConnected(true);
  //         setSeparateEmbodimentWindowOpen(true);
  //         localStorage.setItem(
  //           "sessionOpen",
  //           JSON.stringify({ sessionId, sessionTime })
  //         );
  //       }
  //     }, 3000);
  //     // Clear interval
  //     return () => clearInterval(interval);
  //   } catch (err) {
  //     console.error("Error starting/running Godot check interval:", err);
  //   }
  // }

  // Function to fetch FEAGI status
  const checkFeagi = async (embodimentType) => {
    try {
      const res = await getSessionFeagiStatus(accessToken, sessionId);
      const resData = res.data;
      // Get & set embodiment fitness
      const fitness = resData.fitness; // e.g. 0.347453
      const newFitness =
        typeof fitness === "number"
          ? Math.round(fitness * 100 * 10) / 10 // Scale from 0-1 to 0-100, and round to 1 decimal place
          : null;
      newFitness && setEmbodimentFitness(newFitness);
      const agents = resData.connected_agents; // { media_capture_ctrl: false, bluetooth_ctrl: false, godot_game_ctrl: false, zmq_to_ws_ctrl: false}
      // logCurrentTimestamp();
      if (agents) {
        if (embodimentType === "nrs-html-app") {
          // setIsEmbodimentConnected(agents.godot_game_ctrl);
          props.setGodotEmbodimentOpen(agents.godot_game_ctrl);
        } else if (embodimentType === "user-ws-device") {
          // setIsEmbodimentConnected(agents.zmq_to_ws_ctrl);
          props.setGodotEmbodimentOpen(agents.zmq_to_ws_ctrl);
        }
      } else {
        console.error(
          "Could not get agent data to set embodiment connection status."
        );
      }
    } catch (err) {
      console.error("Error checking FEAGI status:", err);
    }
  };

  // Function to start the interval
  const startFeagiInterval = (embodimentType) => {
    return setInterval(() => {
      checkFeagi(embodimentType);
    }, 15 * 1000); // seconds * 1000
  };

  // Start checking FEAGI status on render
  useEffect(() => {
    // stopInterval.current = startInterval();
    let intervalId;
    if (isHtml || isUserWs) {
      intervalId = startFeagiInterval(experiment.agent_type);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiment, accessToken, sessionId, isHtml, isUserWs]);

  return (
    <>
      <StyledButton
        aria-describedby={id}
        onClick={handleClick}
        disabled={awaitAugment || !experiment || !accessToken}
        sx={{ minWidth: { xs: "24px", md: "64px" } }}
      >
        {isSmOrSmaller ? "Emb" : "Embodiment"}
      </StyledButton>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box display="flex" flexDirection="column" style={{ gap: "10px" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontWeight: "bold" }}>Primary</Typography>
          </Box>
          {!experiment && (
            <Typography style={{ fontStyle: "italic" }}>Loading...</Typography>
          )}

          {/* Title & switch/button */}
          {experiment?.agent_type && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                display="flex"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <Typography
                  sx={{
                    maxWidth: "120px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {experiment.embodiment_title}
                </Typography>
                <Tooltip title="Embodiment info">
                  <IconButton onClick={() => setEmbodimentInfoOpen(true)}>
                    <InfoIcon sx={{ fontSize: "1rem" }} />
                  </IconButton>
                </Tooltip>
              </Box>
              {/* User Websocket or Bluetooth type switch */}
              {(isUserWs || isBt) && (
                <Switch
                  onClick={isUserWs ? handleWsDeviceClick : handleBtDeviceClick}
                  checked={!!isEmbodimentConnected}
                />
              )}
              {/* HTML+JS type button */}
              {isHtml && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleBreakingEmbodimentClick}
                >
                  Launch
                </Button>
              )}
            </Box>
          )}
        </Box>

        {/* Buttons */}
        <Box
          sx={{
            mt: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={() => setEmbodimentReplaceOpen(true)}
          >
            Replace
          </Button>
          {experiment?.has_integration_notes && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => setIntegrationGuidanceOpen(true)}
            >
              {/* <InfoIcon style={{ fontSize: "1.5rem", padding: "4px" }} /> */}
              Integration
            </Button>
          )}
        </Box>

        {/* Divider */}
        <Box
          component="hr"
          sx={{ borderColor: "accents.main", width: "100%", margin: "20px 0" }}
        />

        {/* Auxiliary */}
        <Box display="flex" flexDirection="column">
          <Typography sx={{ fontWeight: "bold" }}>Auxiliary I/O</Typography>
          {/* Webcam */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Webcam</Typography>
            <Tooltip
              title={
                screenCaptureOpen ? "Stop screen capture to use webcam" : ""
              }
            >
              <span>
                <Switch
                  onClick={() => {
                    props.setWebcamOpen((prev) => !prev);
                  }}
                  checked={!!props.webcamOpen}
                  disabled={screenCaptureOpen}
                />
              </span>
            </Tooltip>
          </Box>

          {/* Screen capture */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Screen Capture</Typography>
            <Tooltip
              title={
                props.webcamOpen ? "Stop webcam to use screen capture" : ""
              }
            >
              <span>
                <Switch
                  onClick={() => {
                    setScreenCaptureOpen((prev) => !prev);
                  }}
                  checked={!!screenCaptureOpen}
                  disabled={props.webcamOpen}
                />
              </span>
            </Tooltip>
          </Box>

          {/* Connect different websocket embodiment */}
          <Box
            mt="5px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <Tooltip title="Advanced method to connect different user embodiments">
              <Typography>Custom Connect</Typography>
            </Tooltip>
            <MagicLink
              accessToken={accessToken}
              embodimentId={experiment?.embodiment_id}
              setError={setError}
              link={link}
              setLink={setLink}
              isAuxiliary={true}
            />
          </Box>
        </Box>
      </StyledPopover>

      {/* Embodiment info card popup */}
      {embodimentInfoOpen && (
        <NavbarEmbodimentInfo
          accessToken={accessToken}
          experiment={experiment}
          setError={setError}
          embodimentInfoOpen={embodimentInfoOpen}
          setEmbodimentInfoOpen={setEmbodimentInfoOpen}
        />
      )}

      {/* Integration info popup (how to use FEAGI with it) */}
      {integrationGuidanceOpen && experiment?.embodiment_id && (
        <NavbarEmbodimentGuidance
          accessToken={accessToken}
          id={experiment?.embodiment_id}
          title={experiment?.embodiment_title}
          open={integrationGuidanceOpen}
          setOpen={setIntegrationGuidanceOpen}
        />
      )}

      {/* External websocket connection instructions popup */}
      {wsInstructionsOpen && (
        <EmbodimentInstructions
          accessToken={accessToken}
          embodimentName={experiment?.embodiment_title}
          embodimentId={experiment?.embodiment_id}
          instructionsOpen={wsInstructionsOpen}
          setInstructionsOpen={setWsInstructionsOpen}
          handleClose={() => setWsInstructionsOpen(false)}
          setError={setError}
        />
      )}

      {/* Bluetooth device connection instructions popup */}
      {btInstructionsOpen && (
        <EmbodimentInstructionsBluetooth
          accessToken={accessToken}
          browser={userBrowser}
          embodimentName={experiment?.embodiment_title}
          embodimentId={experiment?.embodiment_id}
          instructionsOpen={btInstructionsOpen}
          setIsBluetoothOpen={setIsBluetoothOpen}
          handleClose={() => setBtInstructionsOpen(false)}
          setError={setError}
        />
      )}

      {/* Bluetooth embodiment */}
      {isBluetoothOpen && (
        <Microbit
          isMicrobitOpen={isBluetoothOpen} // component open
          setIsMicrobitOpen={setIsBluetoothOpen}
          isMicrobitConnected={isEmbodimentConnected} // device connected
          setIsMicrobitConnected={setIsEmbodimentConnected}
        />
      )}

      {/* Godot embodiment */}
      {/* {experiment?.agent_type === "nrs-html-app" &&
        experiment?.embodiment_id && (
          <GodotEmbodiment
            embodimentId={experiment.embodiment_id}
            embodimentOpen={props.godotEmbodimentOpen}
            setEmbodimentOpen={props.setGodotEmbodimentOpen}
          />
        )} */}

      {/* Popup providing link for opening Godot embodiments in new window */}
      <CustomDialog
        isOpen={linkAlertOpen}
        handleClose={() => setLinkAlertOpen(false)}
        header="New Window Required"
        richText={
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {/* <Typography> */}
            {/* To open the embodiment, please open a new window (
              <span style={{ color: "red" }}>NOT a new tab</span>) and paste the
              following link: */}
            {/* (This is required for proper functionality on Chrome.) */}
            {/* </Typography> */}
            {/* <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography fontWeight="300">{embodimentUrl}</Typography>
              <Tooltip title="Copy link">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(embodimentUrl);
                  }}
                >
                  <ContentCopy sx={{ color: "#f3f3f3" }} />
                </IconButton>
              </Tooltip>
            </Box> */}
            <Typography>
              We recommend opening the embodiment in separate window.
              Connectivity will pause if the brain visualizer window or
              embodiment window becomes inactive (out of view). It will
              automatically resume when both windows are active again.
            </Typography>
            {/* {(auth.user?.roles?.includes("pro-user") ||
              auth.user?.roles?.includes("ultimate-user")) && ( */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a
                href={`/play/${
                  newEmbodimentId || experiment?.embodiment_id
                }?session_id=${sessionId}&cluster_id=${clusterId}&ref=brain-visualizer`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="outlined">Launch</Button>
              </a>
            </Box>
            {/* )} */}
          </Box>
        }
      />

      {/* View all embodiments to replace */}
      <CustomDialog
        header="Replace Embodiment"
        maxWidth="lg"
        isOpen={embodimentReplaceOpen}
        handleClose={() => setEmbodimentReplaceOpen(false)}
        richText={
          <Box
            sx={{
              minWidth: { xs: "300px", sm: "500px", md: "700px", lg: "1000px" },
            }}
          >
            <NewExperimentEmbodiment
              onComplete={(id, title) => {
                setNewEmbodimentId(id);
                setNewEmbodimentTitle(title);
                setReplaceConfirmOpen(true);
              }}
              setError={setError}
            />
          </Box>
        }
      />

      {/* Confirm replace */}
      <CustomDialog
        header="Replace your current embodiment?"
        text="This will replace your experiment's embodiment with a embodiment that has
        the selected behavior. Any existing connection will be lost."
        isOpen={replaceConfirmOpen}
        handleClose={() => setReplaceConfirmOpen(false)}
        confirmAction={handleReplaceEmbodiment}
        cancelAction={() => setReplaceConfirmOpen(false)}
      />
    </>
  );
};

export default NavbarEmbodiment;
