import { useEffect, useState } from "react";
import { Alert, Button } from "@mui/material";
import getBrowserInfo from "../util/getBrowserInfo";
import Microbit from "./embodiments-code/Microbit";

const PlayBluetooth = ({ isEmbodimentConnected, setIsEmbodimentConnected }) => {
  const [browser, setBrowser] = useState("");
  const [isBluetoothOpen, setIsBluetoothOpen] = useState(false);

  // Fetch browser info
  useEffect(() => {
    async function fetchBrowserInfo() {
      const { browser } = await getBrowserInfo();
      setBrowser(browser);
    }
    fetchBrowserInfo();
  }, []);

  function handleButtonClick() {
    if (!isEmbodimentConnected) {
      setIsBluetoothOpen(true);
    } else {
      setIsEmbodimentConnected(false);
    }
  }

  return (
    <>
      {(browser === "Safari" || browser === "Firefox") && (
        <Alert severity="warning" sx={{ width: "100%", mb: 2 }}>
          As of our last knowledge, your browser does not support Bluetooth
          connections. Please switch to Chrome for the best experience.
        </Alert>
      )}

      <Button variant="contained" onClick={handleButtonClick}>
        {isEmbodimentConnected ? "Disconnect" : "Connect"}
      </Button>

      {isBluetoothOpen && (
        <Microbit
          isMicrobitOpen={isBluetoothOpen}
          setIsMicrobitOpen={setIsBluetoothOpen}
          isMicrobitConnected={isEmbodimentConnected}
          setIsMicrobitConnected={setIsEmbodimentConnected}
        />
      )}
    </>
  );
};

export default PlayBluetooth;
