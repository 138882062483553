import { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Button,
  Box,
  Collapse,
  Dialog,
  IconButton,
  List,
  ListItemText,
  ListItemButton,
  Menu,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  CheckCircle,
  Close,
  Close as CloseIcon,
  ExpandLess,
  ExpandMore,
  Lock as LockIcon,
  Menu as MenuIcon,
  OpenInNew,
} from "@mui/icons-material";
import { useAuth } from "../util/auth";
import DisplayMarkdown from "../util/DisplayMarkdown";
import PageLoader from "./PageLoader";
import CustomDialog from "./CustomDialog";
import TutorialCompletionActions from "./TutorialCompletionActions";
import { unlockAdvanced } from "../api/userManagement";

function BookModal({
  open,
  onClose,
  setParentError,
  setModalOpen,
  fetchData,
  header,
}) {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const currentSection = data[currentSectionIndex];
  const [currentCompleted, setCurrentCompleted] = useState(
    data[currentSectionIndex]?.completed
  );
  const [slideIndex, setSlideIndex] = useState(0);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const hamburgerOpen = Boolean(anchorEl);
  const APP_BAR_HEIGHT_MOBILE = "56px";
  const APP_BAR_HEIGHT_DESKTOP = "56px";
  const videoRefs = useRef([]);
  const slideRefs = useRef([]);
  const [slideRefsSet, setSlideRefsSet] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [advancedConfirmOpen, setAdvancedConfirmOpen] = useState(false);

  // const fakeButtonAction = {
  //   type: "button",
  //   question:
  //     "Great job working through the walkthrough! If your brain is successfully moving the paddle, you can mark this project as complete.",
  //   button_text: "I did it!",
  // };

  // Set data on load
  useEffect(() => {
    async function callFetchData() {
      try {
        const accessToken = auth.user.accessToken;
        const res = await fetchData(accessToken);
        const resData = res.data?.outline;
        if (res.data?.show_advanced) {
          setShowAdvanced(true);
        }
        if (resData?.length > 0) {
          setData(resData);
        } else {
          throw new Error("Response data from server is empty.");
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setParentError(
          "Sorry, an error occurred fetching the data. Please reload and retry if issues persist."
        );
        setLoading(false);
        setModalOpen(false);
      }
    }

    auth?.user && callFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user, setParentError, setModalOpen]);

  // Close modal and open in new tab
  const handleOpenInNew = () => {
    onClose();
    window.open(`/book/${header.toLowerCase()}`, "_blank");
  };

  // Open/close table of contents on mobile
  const handleHamburgerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHamburgerClose = () => {
    setAnchorEl(null);
  };

  // User click to previous / next section
  const goPrev = () => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex((prev) => prev - 1);
    }
  };

  const goNext = () => {
    if (currentSectionIndex < data.length - 1) {
      setCurrentSectionIndex((prev) => prev + 1);
    }
  };

  // Handle slide images or videos flexibly
  const determineSlideFormat = (url) => {
    if (typeof url !== "string") return null;

    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv"];

    // Get extension & type
    const extension = url.split(".").pop().toLowerCase();

    if (imageExtensions.includes(extension)) {
      return "image";
    } else if (videoExtensions.includes(extension)) {
      return "video";
    } else {
      return null;
    }
  };

  // Handle user click on slide or section header
  const handleSlideClick = (sectionIdx, slideIdx) => {
    if (sectionIdx !== currentSectionIndex) {
      // If switching sections, update the state and uncollapse new section
      setCurrentSectionIndex(sectionIdx);
      setShouldCollapse(false);

      // Set scroll position to top of container
      const contentBox = document.getElementById("slidesParent");
      if (contentBox) {
        contentBox.scrollTop = 0;
      }
    } else {
      if (slideIdx === null || slideIdx === undefined) {
        // If this is a click on a section header, just collapse/uncollapse the section
        setShouldCollapse((prev) => !prev);
      } else {
        // If within same section, just scroll to the slide
        slideRefs.current[sectionIdx]?.[slideIdx]?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        if (hamburgerOpen) {
          setSlideIndex(slideIdx);
          handleHamburgerClose();
        }
      }
    }
  };

  // When section index changes, set slide to 0 and scroll to top
  useEffect(() => {
    setSlideIndex(0);
    const contentBox = document.getElementById("slidesParent");
    if (contentBox) {
      contentBox.scrollTop = 0;
    }
    setSlideRefsSet((prev) => !prev);
    if (data) {
      const isCompleted = data[currentSectionIndex]?.completed;
      setCurrentCompleted(isCompleted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSectionIndex]);

  // Sync current slide to scroll position
  useEffect(() => {
    if (
      slideRefs.current[currentSectionIndex] &&
      slideRefs.current[currentSectionIndex].length > 0
    ) {
      // Snapshot to keep track of current section's slides for cleanup
      const slidesInSection = slideRefs.current[currentSectionIndex] || [];
      // Update slide index on scroll
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const idx = slidesInSection.findIndex(
                (slide) => slide === entry.target
              );
              if (idx !== -1) {
                setSlideIndex(idx);
              }
            }
          });
        },
        { threshold: 0.5 }
      );
      // Set up the observer for the current section's slides
      slidesInSection.forEach((slide) => {
        if (slide instanceof Element) {
          observer.observe(slide);
        }
      });
      // Cleanup function for the current section's slides
      return () => {
        slidesInSection.forEach((slide) => {
          if (slide instanceof Element) {
            observer.unobserve(slide);
          }
        });
        observer.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideRefsSet]);

  // Mute/unmute video on user click
  const handleVolumeChange = (event) => {
    const video = event.target;
    setIsMuted(video.muted);
  };

  // Track scroll/video position to play only on scroll into view
  useEffect(() => {
    // Snapshot the current state of videoRefs
    const currentVideos = [...videoRefs.current];

    // Function to pause all videos except the one that triggered the play event
    const pauseOtherVideos = (currentVideo) => {
      currentVideos.forEach((video) => {
        if (video !== currentVideo) {
          video.pause();
        }
      });
    };

    // Set up what observer should do
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Check if the video element has the 'controls' attribute
          const hasControls = entry.target.hasAttribute("controls");

          if (entry.isIntersecting) {
            // Only play on view if no controls
            if (!hasControls) {
              entry.target.play();
            }
          } else {
            entry.target.pause(); // Pause video on exiting the viewport regardless of controls
          }
        });
      },
      {
        threshold: 0.5, // Customize based on when you want the video to play
      }
    );
    // Observe videos based on the snapshot
    currentVideos.forEach((video) => {
      if (video) {
        observer.observe(video);

        // Attach an event listener to pause other videos when this one plays
        video.addEventListener("play", () => pauseOtherVideos(video));
      }
    });

    // Cleanup on component unmount
    return () => {
      currentVideos.forEach((video) => {
        if (video) {
          observer.unobserve(video);
          // Ideally remove play listener here, but requires storing an array of listener functions
          // video.removeEventListener("play", /* specific reference to the pause function */);
        }
      });
      observer.disconnect();
    };
  }, [data]);

  const handleUnlockAdvanced = async () => {
    try {
      await unlockAdvanced(auth.user.accessToken);
      setShowAdvanced(true);
      setAdvancedConfirmOpen(false);
    } catch (err) {
      console.error("Error from server on unlock:", err);
      setParentError(
        "Error from server on unlock. You may see the unlock reverse on refresh. Please retry if this occurs."
      );
    }
  };

  useEffect(() => {
    console.log("currentCompleted changed");
    if (currentCompleted) {
      setData((prevData) => {
        const newData = [...prevData];
        console.log("currentCompleted prevData:", newData);
        console.log(
          "currentCompleted currentSectionIndex:",
          currentSectionIndex
        );
        newData[currentSectionIndex].completed = true;
        console.log("currentCompleted newData:", newData);
        return newData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompleted]);

  const TableOfContents = () => {
    return (
      <List
        component="nav"
        aria-label="table of contents"
        sx={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          height: {
            xs: `calc(100% - ${APP_BAR_HEIGHT_MOBILE})`,
            md: "100%",
          },
        }}
      >
        {data.map((sectionObject, sectionObjIndex) => {
          if (!showAdvanced && sectionObject.grouping !== "nrs_intro") {
            return null;
          }
          // Section title
          return (
            <Box key={sectionObject.title} sx={{ flexGrow: 0, flexShrink: 1 }}>
              <ListItemButton
                onClick={() => handleSlideClick(sectionObjIndex)}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  // backgroundColor: currentSectionIndex === sectionObjIndex ? "rgba(144, 202, 249, 0.16)" : "inherit",
                  color:
                    currentSectionIndex === sectionObjIndex
                      ? "primary.main"
                      : "",
                }}
              >
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <CheckCircle
                    sx={{
                      fontSize: "1.2rem",
                      color: sectionObject["completed"]
                        ? "primary.main"
                        : "accents.dark",
                      title: "Section completed",
                    }}
                  />
                  <ListItemText
                    primary={sectionObject.title}
                    primaryTypographyProps={{ fontWeight: "600" }}
                  />
                </Box>
                {currentSectionIndex === sectionObjIndex && !shouldCollapse ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItemButton>
              {/* Slide title */}
              {sectionObject.slides.map((slide, sIndex) => (
                <Collapse
                  in={
                    currentSectionIndex === sectionObjIndex && !shouldCollapse
                  }
                  timeout="auto"
                  unmountOnExit
                  key={slide.title}
                >
                  <ListItemButton
                    selected={
                      currentSectionIndex === sectionObjIndex &&
                      slideIndex === sIndex
                    }
                    onClick={() => handleSlideClick(sectionObjIndex, sIndex)}
                    sx={{ mx: 2 }}
                  >
                    <ListItemText
                      primary={slide.title || "Untitled"}
                      primaryTypographyProps={{
                        fontSize: ".9rem",
                        fontStyle: !slide.title ? "italic" : "normal",
                      }}
                    />
                  </ListItemButton>
                </Collapse>
              ))}
            </Box>
          );
        })}
        {!showAdvanced && (
          <Box
            sx={{
              mt: 1,
              mx: 2,
              p: 2,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              borderRadius: "5px",
              backgroundColor: "background.paper",
              color: "accents.light",
              cursor: "pointer",
            }}
            title="Bypass intro and force unlock"
            onClick={() => setAdvancedConfirmOpen(true)}
          >
            <Typography sx={{ fontWeight: "600" }}>Advanced</Typography>
            <Typography
              sx={{
                fontSize: ".8rem",
                fontStyle: "italic",
                textAlign: "center",
              }}
            >
              Complete the intro sections to unlock!
            </Typography>
            <LockIcon />
          </Box>
        )}
      </List>
    );
  };

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "1500px",
          height: "100%",
          overflow: "hidden",
        },
      }}
    >
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {/* Dialog Header */}
          <AppBar
            sx={{
              position: "absolute",
              height: { xs: APP_BAR_HEIGHT_DESKTOP, md: APP_BAR_HEIGHT_MOBILE },
            }}
          >
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                px: 1,
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
              >
                <Box sx={{ display: { xs: "block", md: "none" } }}>
                  {/* Table of Contents (Mobile) */}
                  <Menu
                    id="table-of-contents"
                    aria-labelledby="table-of-contents-button"
                    anchorEl={anchorEl}
                    open={hamburgerOpen}
                    onClose={handleHamburgerClose}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        border: "5px solid #1d1d1d",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: { xs: 1, md: 2, xl: 4 },
                      }}
                    >
                      <IconButton onClick={handleHamburgerClose}>
                        <Close />
                      </IconButton>
                    </Box>
                    <TableOfContents />
                  </Menu>
                  <IconButton
                    id="table-of-contents-button"
                    aria-controls={open ? "table-of-contents" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleHamburgerClick}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>

                {/* Header for both */}
                <Typography
                  variant="h2"
                  fontSize="clamp(1rem, 2vw, 1.5rem)"
                  fontWeight="600"
                >
                  {header}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                {(header === "Tutorials" || header === "Demos") && (
                  <IconButton onClick={handleOpenInNew}>
                    <OpenInNew />
                  </IconButton>
                )}
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={onClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>

          {/* Dialog Content */}
          <Box
            display="flex"
            width="100%"
            height="100%"
            bgcolor="background.default"
          >
            {/* Table of Contents (Desktop) */}
            <Box
              bgcolor="background.default"
              sx={{
                display: { xs: "none", md: "block" },
                mt: { xs: APP_BAR_HEIGHT_MOBILE, md: APP_BAR_HEIGHT_DESKTOP },
                height: {
                  xs: `calc(100% - ${APP_BAR_HEIGHT_MOBILE})`,
                  md: `calc(100% - ${APP_BAR_HEIGHT_DESKTOP})`,
                },
                width: "25%",
              }}
            >
              <TableOfContents />
            </Box>

            {/* Content */}
            <Box
              id="slidesParent"
              bgcolor="background.default"
              // display="flex"
              // justifyContent="flex-end" adding this will make the content fly through top and lose scrollbar
              sx={{
                width: { xs: "100%", md: "75%" },
                height: {
                  xs: `calc(100% - ${APP_BAR_HEIGHT_MOBILE})`,
                  md: `calc(100% - ${APP_BAR_HEIGHT_DESKTOP})`,
                },
                mt: { xs: APP_BAR_HEIGHT_MOBILE, md: APP_BAR_HEIGHT_DESKTOP },
                p: 3,
                overflow: "auto",
              }}
            >
              {currentSection?.slides?.map((slide, sIndex) => (
                <Box
                  key={sIndex}
                  ref={(el) => {
                    if (!slideRefs.current[currentSectionIndex]) {
                      slideRefs.current[currentSectionIndex] = [];
                    }
                    slideRefs.current[currentSectionIndex][sIndex] = el;
                    if (currentSection.slides.length - 1 === sIndex) {
                      setSlideRefsSet((prev) => !prev);
                    }
                  }}
                  sx={{
                    // minHeight: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: { xs: "center", md: "space-between" },
                    alignItems: "center",
                    py: "20px",
                    gap: { xs: 2, md: "" },
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        marginBottom: "10px",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      {slide.title}
                    </Typography>
                  </Box>
                  {determineSlideFormat(slide.url) === "image" ? (
                    <img
                      src={slide.url}
                      alt={slide.title}
                      crossorigin="anonymous"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                    />
                  ) : determineSlideFormat(slide.url) === "video" ? (
                    <video
                      src={`${slide.url}#t=0.001`}
                      crossorigin="anonymous"
                      ref={(el) => {
                        if (el) {
                          if (!videoRefs.current.includes(el)) {
                            videoRefs.current.push(el);
                          }
                        }
                      }}
                      preload="metadata"
                      autoPlay={slide.controls ? false : true}
                      muted={isMuted}
                      loop={slide.loop ? true : false}
                      controls={slide.controls ? true : false}
                      onVolumeChange={handleVolumeChange}
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                    />
                  ) : (
                    <Typography
                      fontStyle="italic"
                      bgcolor="rgba(144, 202, 249, 0.16)"
                      sx={{ my: "20px", padding: "100px" }}
                    >
                      Slide unavailable.
                    </Typography>
                  )}
                  {slide.description && (
                    <Typography
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        fontSize: { xs: "1rem", md: "1.2rem" },
                        fontWeight: "500",
                      }}
                    >
                      <DisplayMarkdown markdownFromDb={slide.description} />
                    </Typography>
                  )}
                </Box>
              ))}
              {/* Quiz / completion button */}
              {currentSection?.completion_action?.length > 0 ? (
                currentSection.completion_action.map((action) => (
                  <TutorialCompletionActions
                    key={action.question}
                    accessToken={auth.user.accessToken}
                    action={action}
                    chapterId={currentSection.title}
                    completed={currentSection.completed}
                    setCompleted={setCurrentCompleted}
                    setShowAdvanced={setShowAdvanced}
                  />
                ))
              ) : (
                <TutorialCompletionActions
                  accessToken={auth.user.accessToken}
                  chapterId={currentSection.title}
                  setCompleted={setCurrentCompleted}
                  completed={currentSection.completed}
                  setShowAdvanced={setShowAdvanced}
                />
              )}

              {/* Previous / Next buttons */}
              <Box display="flex" width="100%" justifyContent="center">
                <Button
                  onClick={goPrev}
                  disabled={currentSectionIndex === 0}
                  variant="outlined"
                  sx={{ mr: "20px" }}
                >
                  Previous
                </Button>
                <Button
                  onClick={goNext}
                  disabled={currentSectionIndex === data.length - 1}
                  variant="outlined"
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
      <CustomDialog
        header="Bypass intro and unlock advanced chapters?"
        text="If you are new to NRS, we highly recommend that you first complete the intro chapters. Are you sure you want to proceed with the unlock?"
        isOpen={advancedConfirmOpen}
        handleClose={() => setAdvancedConfirmOpen(false)}
        confirmAction={handleUnlockAdvanced}
        cancelAction={() => setAdvancedConfirmOpen(false)}
      />
    </Dialog>
  );
}

export default BookModal;
