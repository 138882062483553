import { useLocation, useParams } from "react-router-dom";
import { Box, Card, Typography, CardContent } from "@mui/material";
import ContactSection from "./ContactSection";
import CustomLink from "../util/CustomLink";

const Contact = () => {
  const { type } = useParams();
  const location = useLocation();

  const typeDescriptions = {
    feedback: "Send us your feedback about your experience.",
    support: "Get support for pressing issues.",
    "bug-report": "Report any bugs you've encountered in our system.",
    "get-in-touch":
      "Reach out about partnership, investment, or other inquiries.",
  };

  const renderContent = () => {
    if (location.pathname === "/contact") {
      return (
        <Box
          sx={{
            mt: 8,
            mx: { xs: 3, md: 6, lg: 24 },
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {/* <SectionHeader title="Contact" size={4} textAlign="center" /> */}
          <Typography
            variant="h4"
            sx={{ fontWeight: "500", my: 2, textAlign: "center" }}
          >
            Contact
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {["feedback", "support", "bug-report", "get-in-touch"].map(
              (type, index) => (
                <Box key={index} sx={{ mb: 3, width: "48%" }}>
                  <Card
                    sx={{
                      minHeight: "200px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      boxShadow: 3,
                      borderRadius: 3,
                      border: "1px solid grey",
                      textDecoration: "none",
                      backgroundColor: "background.default",
                    }}
                    component={CustomLink}
                    href={`/contact/${type}`}
                    type="mui"
                  >
                    <CardContent
                      sx={{
                        minHeight: "150px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: { xs: "flex-start", md: "center" },
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: "500", textTransform: "capitalize" }}
                      >
                        {type.replace(/-/g, " ")}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {typeDescriptions[type]}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              )
            )}
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            mt: 8,
            mx: { xs: 2, sm: 4, md: 8, lg: 24 },
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography>
            <CustomLink href="/contact" style={{ color: "white" }} type="mui">
              Contact
            </CustomLink>{" "}
            / {type.replace(/-/g, " ")}
          </Typography>
          <ContactSection />
        </Box>
      );
    }
  };

  return <>{renderContent()}</>;
};

export default Contact;
