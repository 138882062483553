import { useLocation } from "react-router-dom";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import AuthNRSHeader from "./AuthNRSHeader";
import AuthSection from "./AuthSection";
import Capabilities from "./long-content/Capabilities";
import Method from "./long-content/Method";
import blueBrain from "../../img/landing/midjourney-brain.jpg";
import GetStarted from "./long-content/GetStarted";
import UseCases from "./long-content/UseCases";

const AuthLongContent = ({ sx }) => {
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const pathName = location?.pathname;
  const authType = pathName?.substring(pathName.lastIndexOf("/") + 1);

  return (
    <Box
      sx={{
        position: "relative",
        overflowX: "hidden",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        mt: isSmOrSmaller ? 4 : null,
        ...sx,
      }}
    >
      {/* Header */}
      {isSmOrSmaller && <AuthNRSHeader />}
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
          mb: 4,
          mt: { xs: 0, md: "50px" },
          px: {
            xs: "30px",
            md: "30px",
            lg: "70px",
            xl: "100px",
            "2xl": "200px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Brain image */}
        <Box
          sx={{
            width: "100%",
            minHeight: "370px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={blueBrain}
            alt="glowing scribbly blue brain"
            style={{ width: "100%", maxWidth: "500px", objectFit: "contain" }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          {/* Subheader */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                mt: { xs: "-50px", md: "-70px" },
                fontSize: { xs: "2rem", md: "3rem" },
                fontWeight: "700",
              }}
            >
              The future of AI is in your hands
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1rem", md: "1.5rem" },
                color: "accents.light",
              }}
            >
              Learn, build, and share a vast range of AI behaviors
            </Typography>
          </Box>

          {/* Sign up */}
          {isSmOrSmaller && (
            <AuthSection
              bgColor="default"
              size="auto"
              type={authType}
              providers={["github"]}
              afterAuthPath="/lab"
              isBusiness={false}
            />
          )}

          {/* What it can do */}
          {(!isSmOrSmaller ||
            location.pathname === "/" ||
            location.pathname === "/auth/signup") && <Capabilities />}

          {/* Use cases */}
          {(location.pathname === "/" ||
            location.pathname === "/auth/signup") && (
            <UseCases isSmOrSmaller={isSmOrSmaller} />
          )}

          {/* How it works */}
          {(!isSmOrSmaller ||
            location.pathname === "/" ||
            location.pathname === "/auth/signup") && (
            <Method isSmOrSmaller={isSmOrSmaller} />
          )}
        </Box>

        {/* Get started */}
        {(!isSmOrSmaller ||
          location.pathname === "/" ||
          location.pathname === "/auth/signup") && (
          <GetStarted isSmOrSmaller={isSmOrSmaller} />
        )}
      </Box>
    </Box>
  );
};

export default AuthLongContent;
